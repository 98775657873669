import { merge } from 'lodash';
import Cookies from 'js-cookie';
var cookies = {};

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var cookieSetting = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var currentCookieSetting = {
    expires: 1
  };
  merge(currentCookieSetting, cookieSetting);
  Cookies.set("".concat(name), value, currentCookieSetting);
};

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  return Cookies.get("".concat(name));
};

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get();
};

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  return Cookies.remove("".concat(name));
};
export default cookies;