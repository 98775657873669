import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import store from "@/store";
function checkPermission(el, binding) {
  var value = binding.value;
  var funcs = store.state && store.state.d2admin && store.state.d2admin.user && store.state.d2admin.user.info.funcs;
  if (value && value instanceof Array) {
    if (value.length > 0) {
      var permissionRoles = value;
      var hasPermission = false;
      if (funcs && funcs.length > 0) {
        hasPermission = funcs.some(function (func) {
          return permissionRoles.includes(func.code);
        });
      }
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
  }
}
export default {
  inserted: function inserted(el, binding) {
    checkPermission(el, binding);
  },
  update: function update(el, binding) {
    checkPermission(el, binding);
  }
};