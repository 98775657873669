import "core-js/modules/es.array.concat.js";
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require("@/libs/util.import." + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: "/",
  redirect: {
    name: "index"
  }
}, {
  path: "/index",
  name: "index",
  meta: {
    title: "",
    auth: true,
    code: "customer"
  },
  component: _import("system/home/index")
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: "/login",
  name: "login",
  component: _import("system/login")
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: "*",
  name: "404",
  component: _import("system/error/404")
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);