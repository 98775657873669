import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.self.js";
var subscribeComponent = {
  top: false,
  wssUrl: window.location.host !== 'localhost:8080' ? "wss://" + window.location.host + "/websocket" : 'ws://127.0.0.1:8036',
  wss: undefined,
  wssConnected: false,
  wssOpened: undefined,
  wssRequstId: 1,
  keepAliveInterval: 15000,
  keepAliveTimer: null,
  bet188CallBack: undefined,
  warningCallBack: undefined,
  monitorCallBack: undefined,
  scoreWarningCallBack: undefined,
  scoreWaringParams: [],
  packet: {
    protocol: 0,
    message: undefined
  },
  message: {
    id: undefined
  },
  dispose: false,
  uuid: undefined,
  timeoutId: undefined,
  callBack: new Object(),
  init: function init(id) {
    var ins = this;
    if (window.parent === window.self) {
      ins.top = true;
    }
    ins.uuid = id;
    //开始创建连接
    ins.createWss();
  },
  clearState: function clearState() {
    var ins = this;
    console.log('clear state');
    ins.wssConnected = false;
    ins.clearKeepAlive();
    if (ins.wss) {
      ins.wss.onmessage = undefined;
      ins.wss.onclose = undefined;
      ins.wss.onerror = undefined;
    }
    ins.wss = undefined;
    if (ins.timeoutId) {
      clearTimeout(ins.timeoutId);
    }
    ins.timeoutId = setTimeout(function () {
      ins.createWss();
    }, 5000);
  },
  close: function close() {
    var ins = this;
    try {
      ins.dispose = true;
      if (ins.wssConnected) {
        ins.wss.close();
      }
      console.log('close websocks');
    } catch (e) {}
    try {
      ins.wssConnected = false;
      ins.clearKeepAlive();
      ins.wss.onmessage = undefined;
      ins.wss.onclose = undefined;
      ins.wss.onerror = undefined;
      ins.wss = undefined;
      if (ins.timeoutId) {
        clearTimeout(ins.timeoutId);
      }
    } catch (e) {}
  },
  createWss: function createWss() {
    var ins = this;
    if ("WebSocket" in window || "MozWebSocket" in window) {
      ins.wss = new WebSocket(ins.wssUrl);
      ins.wss.onopen = function () {
        ins.wssConnected = true;
        ins.startKeepAlive();
        if (ins.wssOpened) {
          ins.wssOpened();
        }
      };
      ins.wss.onmessage = function (e) {
        try {
          var pkt = JSON.parse(e.data);
          switch (pkt.protocol) {
            case 998:
              {
                var sub = JSON.parse(pkt.message);
                switch (sub.protocol) {
                  case 2:
                    if (ins.warningCallBack) {
                      ins.warningCallBack(JSON.parse(sub.message));
                    }
                    break;
                  case 3:
                    if (ins.monitorCallBack) {
                      ins.monitorCallBack(JSON.parse(sub.message));
                    }
                    break;
                  case 4:
                    if (ins.scoreWarningCallBack) {
                      ins.scoreWarningCallBack(JSON.parse(sub.message));
                    }
                    break;
                  case 6:
                    if (ins.bet188CallBack) {
                      ins.bet188CallBack(JSON.parse(sub.message));
                    }
                    break;
                  default:
                    break;
                }
                break;
              }
            case 999:
              {
                var data = JSON.parse(pkt.message);
                if (data && data.id) {
                  var call = ins.callBack[data.id];
                  if (call) {
                    call(data);
                  }
                }
                break;
              }
            default:
              break;
          }
        } catch (err) {
          console.log("协议错误~~" + e.data);
        }
      };
      ins.wss.onclose = function (e) {
        if (ins.dispose) return;
        ins.clearState();
      };
      ins.wss.onerror = function (e) {
        if (ins.dispose) return;
        ins.clearState();
      };
    }
  },
  subscribe: function subscribe() {
    var ins = this;
    var pkt = Object.assign({}, ins.packet);
    var sub = {
      protocol: 2,
      message: ''
    }; //报警信息
    var msg = Object.assign({
      account: ins.uuid
    }, ins.message);
    var nowTicks = new Date().getTime();
    pkt.protocol = 1; //订阅
    msg.id = ins.wssRequstId++;
    msg.ticks = nowTicks;
    sub.message = JSON.stringify(msg);
    pkt.message = JSON.stringify(sub);
    //发送数据包
    ins.sendWssPacket(msg.id, pkt, function (data) {
      console.log(data);
    });
  },
  subscribeBet188: function subscribeBet188() {
    var ins = this;
    var pkt = Object.assign({}, ins.packet);
    var sub = {
      protocol: 6,
      message: ''
    }; //bet188
    var msg = Object.assign({
      account: ins.uuid
    }, ins.message);
    var nowTicks = new Date().getTime();
    pkt.protocol = 1; //订阅
    msg.id = ins.wssRequstId++;
    msg.ticks = nowTicks;
    sub.message = JSON.stringify(msg);
    pkt.message = JSON.stringify(sub);
    //发送数据包
    ins.sendWssPacket(msg.id, pkt, function (data) {
      console.log(data);
    });
  },
  setScoreWaringParams: function setScoreWaringParams(mode, value) {
    var flag = false;
    for (var x = 0; x < this.scoreWaringParams.length; x++) {
      var item = this.scoreWaringParams[x];
      if (item.mode === mode) {
        item.val = value;
        flag = true;
      }
    }
    if (flag === false) {
      this.scoreWaringParams.push({
        mode: mode,
        val: value
      });
    }
    this.sendSetScoreWaringParams(mode);
  },
  sendSetScoreWaringParams: function sendSetScoreWaringParams(mode) {
    var ins = this;
    for (var x = 0; x < ins.scoreWaringParams.length; x++) {
      var item = ins.scoreWaringParams[x];
      if (item.mode === mode) {
        var _ins = this;
        var pkt = Object.assign({}, _ins.packet);
        var sub = {
          protocol: mode + 2,
          message: ''
        }; //报警信息
        var msg = Object.assign({
          account: _ins.uuid
        }, _ins.message);
        var nowTicks = new Date().getTime();
        pkt.protocol = 2; //数据交互
        msg.id = _ins.wssRequstId++;
        msg.ticks = nowTicks;
        msg.count = item.val;
        sub.message = JSON.stringify(msg);
        pkt.message = JSON.stringify(sub);
        //发送数据包
        _ins.sendWssPacket(msg.id, pkt, function (data) {
          console.log(data);
        });
        console.log('[score send]' + mode + ',val:' + item.val);
      }
    }
  },
  startKeepAlive: function startKeepAlive() {
    if (this.keepAliveTimer) {
      clearInterval(this.keepAliveTimer);
      this.keepAliveTimer = null;
    }
    var ins = this;
    this.keepAliveTimer = setInterval(function () {
      ins.keepAlive();
    }, this.keepAliveInterval);
  },
  clearKeepAlive: function clearKeepAlive() {
    if (this.keepAliveTimer) {
      clearInterval(this.keepAliveTimer);
      this.keepAliveTimer = null;
    }
  },
  keepAlive: function keepAlive() {
    var ins = this;
    var pkt = Object.assign({}, ins.packet);
    var sub = {
      message: ''
    }; //心跳
    var msg = Object.assign({
      account: ins.uuid
    }, ins.message);
    var nowTicks = new Date().getTime();
    pkt.protocol = 1000; //心跳
    msg.id = ins.wssRequstId++;
    msg.ticks = nowTicks;
    sub.message = JSON.stringify(msg);
    pkt.message = JSON.stringify(sub);
    //发送数据包
    ins.sendWssPacket(msg.id, pkt, function (data) {
      console.log(data);
    });
  },
  isOpen: function isOpen() {
    return this.wssConnected && this.wss.readyState === this.wss.OPEN;
  },
  sendWssPacket: function sendWssPacket(id, pkt, response) {
    var ins = this;
    try {
      if (ins.isOpen()) {
        ins.callBack[id] = function (data) {
          response(data);
          ins.callBack[id] = undefined;
        };
        ins.wss.send(JSON.stringify(pkt));
      } else {
        ins.clearState();
      }
    } catch (e) {
      ins.clearState();
    }
  }
};
export default subscribeComponent;