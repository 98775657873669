import _objectSpread from "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import md5 from 'js-md5';
import { mapActions } from 'vuex';
import localeMixin from '@/locales/mixin.js';
export default {
  mixins: [localeMixin],
  data: function data() {
    return {
      // 表单
      formLogin: {
        username: '',
        password: ''
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  created: function created() {
    var ins = this;
    this.loadTemp().then(function (res) {
      var temp = ins.$store.state.d2admin.user.temp;
      if (temp) {
        if (temp.username) {
          ins.formLogin.username = temp.username;
        }
        if (temp.password) {
          ins.formLogin.password = temp.password;
        }
      }
    });
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('d2admin/account', ['login', 'logout'])), mapActions('d2admin/user', ['loadTemp', 'setTemp'])), {}, {
    /**
     * @description 接收选择一个用户快速登录的事件
     * @param {Object} user 用户信息
     */
    handleUserBtnClick: function handleUserBtnClick(user) {
      this.formLogin.username = user.username;
      this.formLogin.password = user.password;
      this.submit();
    },
    havePermission: function havePermission(code) {
      var store = this.$store;
      if (store.state && store.state.d2admin && store.state.d2admin.user && store.state.d2admin.user.info.funcs) {
        var find = store.state.d2admin.user.info.funcs.find(function (x) {
          return x.code === code;
        });
        if (find) return true;
        return false;
      }
      return undefined;
    },
    startAudio1: function startAudio1() {
      try {
        // 获取元素标签
        var warning = document.getElementById('warningAudio');
        // 触发交互
        warning.pause();
      } catch (error) {}
    },
    startAudio2: function startAudio2() {
      try {
        // 获取元素标签
        var warning = document.getElementById('warningAudioScroll');
        // 触发交互
        warning.pause();
      } catch (error) {}
    },
    /**
     * @description 提交表单
     */
    // 提交登录信息
    submit: function submit() {
      var _this = this;
      this.startAudio1();
      this.startAudio2();
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 登录
          // 注意 这里的演示没有传验证码
          // 具体需要传递的数据请自行修改代码
          _this.login({
            UserName: _this.formLogin.username,
            Password: md5(_this.formLogin.password)
          }).then(function () {
            if (_this.havePermission('customer') === false) {
              _this.$message.error('您的账户未授权登陆系统~');
              _this.logout();
              return;
            }
            _this.setTemp(Object.assign({}, _this.formLogin));
            // 重定向对象不存在则返回顶层路径
            _this.$router.replace(_this.$route.query.redirect || '/');
          }).catch(function (rst) {
            console.log(rst);
          });
        } else {
          // 登录表单校验失败
          _this.$message.error('请输入正确的账户名以及密码');
        }
      });
    }
  })
};