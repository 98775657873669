import "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/core-js/modules/es.array.iterator.js";
import "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/core-js/modules/es.promise.js";
import "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/core-js/modules/es.object.assign.js";
import "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/core-js/modules/es.promise.finally.js";
// Vue
import Vue from "vue";
import i18n from "./i18n";
import App from "./App";
// 核心插件
import d2Admin from "@/plugin/d2admin";
// store
import store from "@/store/index";
// 菜单和路由设置
import router from "./router";
import permission from "@/libs/permission/index.js";

// 核心插件
Vue.use(d2Admin);
Vue.use(permission);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit("d2admin/releases/versionShow");
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch("d2admin/account/load");
    // 获取并记录用户 UA
    this.$store.commit("d2admin/ua/get");
    // 初始化全屏监听
    this.$store.dispatch("d2admin/fullscreen/listen");
  },
  watch: {
    // 检测路由变化切换侧边栏内容
    "$route.matched": {
      handler: function handler(matched) {
        if (matched.length > 0) {}
      },
      immediate: true
    }
  }
}).$mount("#app");