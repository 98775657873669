import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import cookies from './util.cookies';
import db from './util.db';
import log from './util.log';
var util = {
  cookies: cookies,
  db: db,
  log: log
};

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  var processTitle = [process.env.VUE_APP_TITLE, process.env.VUE_APP_VERSION_CODE, process.env.VUE_APP_TITLE_SUFFIX].join(' ') || 'D2Admin';
  window.document.title = "".concat(processTitle).concat(titleText ? " | ".concat(titleText) : '');
};

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function (url) {
  var a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('id', 'd2admin-link-temp');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(document.getElementById('d2admin-link-temp'));
};
export default util;