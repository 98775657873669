export default (function (_ref) {
  var service = _ref.service,
    request = _ref.request,
    serviceForMock = _ref.serviceForMock,
    requestForMock = _ref.requestForMock,
    mock = _ref.mock,
    faker = _ref.faker,
    tools = _ref.tools;
  return {
    /**
     * 平台
     */
    Platforms: function Platforms() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/Platforms",
        method: "POST",
        data: data
      });
    },
    /**
     * 赛事
     */
    Sports: function Sports() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/Sports",
        method: "POST",
        data: data
      });
    },
    /**
     * 利润套利接口
     */
    ProfitArbitrage: function ProfitArbitrage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/ProfitArbitrage",
        method: "POST",
        data: data
      });
    },
    /**
     * 区间套利
     */
    RangeArbitrage: function RangeArbitrage() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/RangeArbitrage",
        method: "POST",
        data: data
      });
    },
    /**
     * 超值数据
     */
    GreatValueData: function GreatValueData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/GreatValueData",
        method: "POST",
        data: data
      });
    },
    /**
     * 皇冠专区
     */
    CrownArea: function CrownArea() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/CrownArea",
        method: "POST",
        data: data
      });
    },
    /**
     * 计算器
     */
    Calculator: function Calculator() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/Calculator",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    Notices: function Notices() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/Notices",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    Basketball: function Basketball() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/Basketball",
        method: "GET",
        data: data
      });
    },
    /**
     * 通知消息
     */
    ClearScrollFreqMonitorParam: function ClearScrollFreqMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/ClearScrollFreqMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    QueryScrollFreqMonitorParam: function QueryScrollFreqMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/QueryScrollFreqMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    AddScrollFreqMonitorParam: function AddScrollFreqMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/AddScrollFreqMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    ClearScrollFreqTCMonitorParam: function ClearScrollFreqTCMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/ClearScrollFreqTCMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    QueryScrollFreqTCMonitorParam: function QueryScrollFreqTCMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/QueryScrollFreqTCMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    AddScrollFreqTCMonitorParam: function AddScrollFreqTCMonitorParam() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/AddScrollFreqTCMonitorParam",
        method: "POST",
        data: data
      });
    },
    /**
     * 通知消息
     */
    BasketballScroll: function BasketballScroll() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/Surebet/BasketballScrolls",
        method: "GET",
        data: data
      });
    }
  };
});