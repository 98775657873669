var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("router-view"), _c("audio", {
    staticClass: "audio",
    attrs: {
      src: require("@/assets/video/warnings.mp3?t=2024041302"),
      controls: "",
      id: "warningAudio",
      hidden: "true"
    }
  }), _c("audio", {
    staticClass: "audio",
    attrs: {
      src: require("@/assets/video/warnings-scroll.mp3?t=2024041302"),
      controls: "",
      id: "warningAudioScroll",
      hidden: "true"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };