import _objectSpread from "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import util from '@/libs/util';
import { mapState } from 'vuex';
export default {
  name: 'app',
  data: function data() {
    return {
      timer: undefined
    };
  },
  computed: _objectSpread({}, mapState('d2admin', {
    user: function user(state) {
      return state.user.info;
    }
  })),
  mounted: function mounted() {
    this.$nextTick(function () {
      // 禁用右键
      document.oncontextmenu = function (event) {
        event.returnValue = false;
      };
      // 禁用F12
      document.onkeydown = function () {
        if (window.event && window.event.keyCode == 123) {
          return false;
        }
      };
    });
  },
  beforeDestroy: function beforeDestroy() {
    document.onkeydown = function () {
      if (window.event && window.event.keyCode == 123) {
        return true;
      }
    };
    document.oncontextmenu = function (event) {
      event.returnValue = true;
    };
  },
  watch: {
    '$i18n.locale': 'i18nHandle',
    user: {
      handler: function handler(newVal, oldVal) {
        if (newVal && newVal.funcs) {}
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
    var ins = this;
    ins.timer = setInterval(function () {
      ins.keepLogin();
    }, 1000);
  },
  methods: {
    keepLogin: function keepLogin() {
      if (this.user) {
        this.$store.dispatch('d2admin/user/refresh');
      }
    },
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  }
};