import _typeof from "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _objectSpread from "/Volumes/Storehouse/Documents/development/freelance/ty/\u524D\u7AEF\u7F51\u7AD9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import Clipboard from 'clipboard';
import subscribeComponent from '@/libs/util.wss';
import Notify from '@wcjiang/notify';
export default {
  data: function data() {
    return {
      loading: false,
      current: undefined,
      dayjs: dayjs,
      change: 0,
      lang: 'zh',
      notifyInstance: undefined,
      total: 0,
      audioInit: false,
      playing: false,
      wss: subscribeComponent,
      playingScroll: false,
      selectedIndex: -1,
      warningSelectIndex: -1,
      notices: [],
      notice: undefined,
      noticeTimer: undefined,
      expriesTime: undefined,
      warningNotRead: 0,
      hedgingWarningNotRead: 0,
      dialogFormVisible: true,
      setRefreshIntervalTimer: undefined,
      basketballLoading: false,
      basketballScrollLoading: false,
      basketballScrollTimer: undefined,
      basketballTimer: undefined,
      basketballToDayWarningResize: 0,
      hedgingWarningResize: 0,
      sizeFreq: {
        team: undefined,
        rate: undefined,
        volNotice: true,
        category: '大小'
      },
      letFreq: {
        team: undefined,
        rate: undefined,
        volNotice: true,
        category: '让球'
      },
      sizeFreqTC: {
        team: undefined,
        volNotice: true,
        category: '大小'
      },
      letFreqTC: {
        team: undefined,
        volNotice: true,
        category: '让球'
      },
      basketball: {
        code: 'Basketball',
        text: '威圖篮球',
        per: false,
        mode: 0,
        today: {
          list: [],
          time: new Date(),
          warnings: [],
          config: {
            floatWarning: true,
            autoRefresh: true,
            refreshInterval: 15,
            rv1: 3,
            bv1: 3,
            rv2: 1.5,
            bv2: 1.5
          }
        },
        scroll: {
          list: [],
          time: new Date(),
          warnings: [],
          config: {
            floatWarning: true,
            floatWarning1: true,
            floatWarning2: true,
            autoRefresh: true,
            skipView: '1',
            skips: [],
            refreshInterval: 5,
            scoreFullCount: 0,
            scoreHalfCount: 0,
            ov1: 10.0,
            un1: 10.0,
            ov2: 15.0,
            un2: 15.0
          }
        }
      },
      hedging: {
        code: 'hedging',
        text: '威圖对冲',
        mode: 0,
        per: false,
        list: [],
        warnings: [],
        time: new Date(),
        config: {
          floatWarning: true,
          skips: [],
          skipView: true
        }
      },
      categories: [{
        code: 'ProfitArbitrage',
        text: '利润套利',
        lang: 'zh',
        fav: false,
        per: false,
        valueText: '利润',
        allPlatformChecked: false,
        allTysSportChecked: false,
        allGmeSportChecked: false,
        listQuery: {
          sports: [],
          platforms: [],
          time: 12 * 60 * 60,
          cursor: undefined,
          value: 2
        },
        list: [],
        refreshTimer: 0,
        platforms: [],
        tysSports: [],
        gmeSports: []
      }, {
        code: 'RangeArbitrage',
        text: '区间套利',
        lang: 'zh',
        fav: false,
        per: false,
        valueText: '亏损率',
        allPlatformChecked: false,
        allTysSportChecked: false,
        allGmeSportChecked: false,
        refreshTimer: 0,
        listQuery: {
          sports: [],
          platforms: [],
          time: 12 * 60 * 60,
          cursor: undefined,
          value: 0
        },
        list: [],
        platforms: [],
        tysSports: [],
        gmeSports: []
      }, {
        code: 'CrownArea',
        text: '皇冠专区',
        valueText: '利润',
        allPlatformChecked: false,
        allTysSportChecked: false,
        allGmeSportChecked: false,
        refreshTimer: 0,
        lang: 'zh',
        fav: false,
        per: false,
        listQuery: {
          sports: [],
          platforms: [],
          time: 24 * 60 * 60,
          cursor: undefined,
          value: 3
        },
        list: [],
        platforms: [],
        tysSports: [],
        gmeSports: []
      }, {
        code: 'GreatValueData',
        text: '超值数据',
        lang: 'zh',
        fav: false,
        per: false,
        valueText: '超值率',
        allPlatformChecked: false,
        allTysSportChecked: false,
        allGmeSportChecked: false,
        refreshTimer: 0,
        listQuery: {
          sports: [],
          platforms: [],
          time: 48 * 60 * 60,
          cursor: undefined,
          value: 1.5
        },
        list: [],
        platforms: [],
        tysSports: [],
        gmeSports: []
      }, {
        code: 'Basketball',
        text: '威圖篮球',
        mode: 0,
        per: false,
        today: {
          list: [],
          time: new Date(),
          warnings: [],
          config: {
            floatWarning: true,
            autoRefresh: true,
            refreshInterval: 15,
            rv1: 3,
            bv1: 3,
            rv2: 1.5,
            bv2: 1.5
          }
        },
        scroll: {
          list: [],
          time: new Date(),
          warnings: [],
          config: {
            floatWarning: true,
            floatWarning1: true,
            floatWarning2: true,
            autoRefresh: true,
            skipView: '1',
            skips: [],
            refreshInterval: 5,
            scoreFullCount: 0,
            scoreHalfCount: 0,
            ov1: 10.0,
            un1: 10.0,
            ov2: 15.0,
            un2: 15.0
          }
        }
      }, {
        code: 'hedging',
        text: '威圖对冲',
        mode: 0,
        per: false,
        list: [],
        warnings: [],
        time: new Date(),
        config: {
          floatWarning: true,
          skips: [],
          skipView: true
        }
      }],
      timeItems: [{
        id: 1,
        name: '12小时',
        key: 12 * 60 * 60
      }, {
        id: 2,
        name: '16小时',
        key: 16 * 60 * 60
      }, {
        id: 3,
        name: '1天',
        key: 24 * 60 * 60
      }, {
        id: 4,
        name: '2天',
        key: 48 * 60 * 60
      }, {
        id: 5,
        name: '7天',
        key: 7 * 24 * 60 * 60
      }],
      calculationData: [{
        left: '0.5',
        right: '0.5'
      }, {
        left: '0.5',
        right: '1.0'
      }, {
        left: '1.0',
        right: '1.5'
      }, {
        left: '0.5',
        right: '1.5'
      }]
    };
  },
  mounted: function mounted() {
    if (this.watchBasketBall || this.watchHedging) {
      this.bindwarningAudioEnd();
      this.bindwarningScrollAudioEnd();
      this.bindwarningFloatSize();
    }
  },
  created: function created() {
    this.loadCache();
    this.getNotices();
    if (this.watchSurebet) {
      this.current = this.categories[0];
      this.getPlatforms();
      this.getSports();
    }
    if (this.watchBasketBall || this.watchHedging) {
      if (!this.watchSurebet && !this.watchHedging) {
        this.current = this.categories[this.categories.length - 2];
      }
      if (!this.watchSurebet && this.watchHedging) {
        this.current = this.categories[this.categories.length - 1];
      }
      if (this.watchBasketBall) {
        this.startQueryBasketball();
        this.basketball.mode = 0;
        this.startQueryBasketballScroll();
        this.queryFreq();
      }
      this.createWebSocks();
    }
    this.createNotify();
  },
  beforeDestroy: function beforeDestroy() {
    var ins = this;
    if (ins.noticeTimer) {
      clearInterval(ins.noticeTimer);
    }
    if (ins.setRefreshIntervalTimer) {
      clearTimeout(ins.setRefreshIntervalTimer);
    }
    if (ins.basketballTimer) {
      clearInterval(ins.basketballTimer);
    }
    if (ins.basketballScrollTimer) {
      clearInterval(ins.basketballScrollTimer);
    }
    window.onresize = null;
    if (ins.wss) {
      ins.wss.warningCallBack = undefined;
      ins.wss.monitorCallBack = undefined;
      ins.wss.scoreWarningCallBack = undefined;
      ins.wss.bet188CallBack = undefined;
      ins.wss.close();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/user', ['setQuery'])), {}, {
    queryFreq: function queryFreq() {
      var ins = this;
      ins.$api.QueryScrollFreqMonitorParam({
        category: '大小'
      }).then(function (res) {
        if (res && res.data) {
          ins.sizeFreq = Object.assign({}, res.data);
        }
      });
      ins.$api.QueryScrollFreqMonitorParam({
        category: '让球'
      }).then(function (res) {
        if (res && res.data) {
          ins.letFreq = Object.assign({}, res.data);
        }
      });
      ins.$api.QueryScrollFreqTCMonitorParam({
        category: '大小'
      }).then(function (res) {
        if (res && res.data) {
          ins.sizeFreqTC = Object.assign({}, res.data);
        }
      });
      ins.$api.QueryScrollFreqTCMonitorParam({
        category: '让球'
      }).then(function (res) {
        if (res && res.data) {
          ins.letFreqTC = Object.assign({}, res.data);
        }
      });
    },
    sizeFreqTCCheckChanged: function sizeFreqTCCheckChanged(e, v) {
      if (e.target.checked) {
        this.sizeFreqTC.team = v;
        this.$api.AddScrollFreqTCMonitorParam(Object.assign({}, this.sizeFreqTC)).then(function (res) {});
      } else {
        this.sizeFreqTC.team = undefined;
        this.$api.ClearScrollFreqTCMonitorParam({
          category: this.sizeFreqTC.category
        }).then(function (res) {});
      }
    },
    letFreqTCCheckChanged: function letFreqTCCheckChanged(e, v) {
      if (e.target.checked) {
        this.letFreqTC.team = v;
        this.$api.AddScrollFreqTCMonitorParam(Object.assign({}, this.letFreqTC)).then(function (res) {});
      } else {
        this.letFreqTC.team = undefined;
        this.$api.ClearScrollFreqTCMonitorParam({
          category: this.letFreqTC.category
        }).then(function (res) {});
      }
    },
    sizeFreqCheckChanged: function sizeFreqCheckChanged(e, v) {
      if (e.target.checked) {
        this.sizeFreq.team = v;
        if (this.sizeFreq.rate) {
          this.$api.AddScrollFreqMonitorParam(Object.assign({}, this.sizeFreq)).then(function (res) {});
        }
      } else {
        this.sizeFreq.team = undefined;
        this.$api.ClearScrollFreqMonitorParam({
          category: this.sizeFreq.category
        }).then(function (res) {});
      }
    },
    sizeFreqRateChanged: function sizeFreqRateChanged() {
      debugger;
      if (this.sizeFreq.rate && (this.sizeFreq.team || this.sizeFreq.team === 0)) {
        this.$api.AddScrollFreqMonitorParam(Object.assign({}, this.sizeFreq)).then(function (res) {});
      }
    },
    letFreqCheckChanged: function letFreqCheckChanged(e, v) {
      if (e.target.checked) {
        this.letFreq.team = v;
        if (this.letFreq.rate) {
          this.$api.AddScrollFreqMonitorParam(Object.assign({}, this.letFreq)).then(function (res) {});
        }
      } else {
        this.letFreq.team = undefined;
        this.$api.ClearScrollFreqMonitorParam({
          category: this.letFreq.category
        }).then(function (res) {});
      }
    },
    letFreqRateChanged: function letFreqRateChanged() {
      if (this.letFreq.rate && (this.letFreq.team || this.letFreq.team === 0)) {
        this.$api.AddScrollFreqMonitorParam(Object.assign({}, this.letFreq)).then(function (res) {});
      }
    },
    createNotify: function createNotify() {
      try {
        this.notifyInstance = new Notify({
          effect: "flash",
          interval: 500,
          disableFavicon: true,
          notification: {
            title: "通知",
            icon: "favicon.ico",
            body: "通知消息"
          }
        });
        this.showNotify('系统提示', '当前登录账户有效期至:' + this.expriesTime);
      } catch (e) {}
    },
    showNotify: function showNotify(title, msg, url) {
      try {
        var ins = this;
        var openUrl = url;
        ins.notifyInstance.notify({
          title: title,
          body: msg,
          icon: 'favicon.ico',
          disableFavicon: true,
          onclick: function onclick() {
            try {
              if (openUrl) {
                ins.openNewWindow(openUrl);
              }
            } catch (e) {}
          },
          onshow: function onshow() {}
        });
      } catch (e) {}
    },
    createWebSocks: function createWebSocks() {
      if (this.watchScroll || this.watchHedging) {
        this.wss.init(this.uuid());
        this.wss.wssOpened = this.openedWebSocks;
      }
    },
    bet188Notify: function bet188Notify(rsp) {
      this.hedging.time = new Date();
      if (rsp.warnings && rsp.warnings.length > 0) {
        for (var x = 0; x < rsp.warnings.length; x++) {
          var item = rsp.warnings[x];
          var copy = cloneDeep(Object.assign({
            now: new Date()
          }, item));
          this.hedging.warnings.splice(0, 0, copy);
          this.warningSelectIndex = -1;
          if (this.current && this.current.code !== 'hedging') {
            this.hedgingWarningNotRead++;
          }
        }
        this.playWarningAudio();
      }
      this.hedging.list = rsp.data;
    },
    openedWebSocks: function openedWebSocks() {
      if (this.watchScroll) {
        this.wss.warningCallBack = this.warningCallBack;
        this.wss.monitorCallBack = this.monitorCallBack;
        this.wss.scoreWarningCallBack = this.scoreWarningCallBack;
        this.wss.subscribe();
        this.wss.sendSetScoreWaringParams(0);
        this.wss.sendSetScoreWaringParams(1);
      }
      if (this.watchHedging) {
        this.wss.bet188CallBack = this.bet188Notify;
        this.wss.subscribeBet188();
      }
    },
    scoreWarningCallBack: function scoreWarningCallBack(data) {
      if (this.watchScroll) {
        console.log(data);
        var mode = data.mode;
        var id = data.id;
        var warning = data.warningId;
        var url = '';
        if (data.category) {
          switch (data.category) {
            case '让球':
              url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&sid=' + warning;
              break;
            case '大小':
              url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&sid=' + warning;
              break;
          }
        }
        if (url !== '') {
          this.openNewWindow(url);
          this.playWarningScrollAudio();
        }
      }
    },
    warningCallBack: function warningCallBack(data) {
      if (this.watchScroll) {
        var mode = data.mode;
        var id = data.id;
        var warning = data.warningId;
        var url = '';
        if (!data.freq) {
          if (this.basketball.scroll.config.floatWarning1 && mode === '全场') {
            if (data.category) {
              switch (data.category) {
                case '让球':
                  url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&warning=' + warning;
                  break;
                case '大小':
                  url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&warning=' + warning;
                  break;
              }
            }
            if (url !== '') {
              //全场
              this.openNewWindow(url);
              this.playWarningAudio();
            }
          }
          if (this.basketball.scroll.config.floatWarning2 && mode === '半场') {
            if (data.category) {
              switch (data.category) {
                case '让球':
                  url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&warning=' + warning;
                  break;
                case '大小':
                  url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&warning=' + warning;
                  break;
              }
            }
            if (url !== '') {
              //半场
              this.openNewWindow(url);
              this.playWarningAudio();
            }
          }
        } else {
          var monitor = data.monitor;
          if (monitor) {
            switch (monitor.monitorType) {
              case "F":
                {
                  if (mode === '全场') {
                    if (data.category) {
                      switch (data.category) {
                        case '让球':
                          url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&freqWarning=' + warning;
                          break;
                        case '大小':
                          url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&freqWarning=' + warning;
                          break;
                      }
                    }
                    if (url !== '') {
                      //全场
                      this.openNewWindow(url);
                      if (monitor.volNotice) {
                        this.playWarningAudio();
                      }
                    }
                  }
                  if (mode === '半场') {
                    if (data.category) {
                      switch (data.category) {
                        case '让球':
                          url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&freqWarning=' + warning;
                          break;
                        case '大小':
                          url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&freqWarning=' + warning;
                          break;
                      }
                    }
                    if (url !== '') {
                      //半场
                      this.openNewWindow(url);
                      if (monitor.volNotice) {
                        this.playWarningAudio();
                      }
                    }
                  }
                  break;
                }
              case "FTC":
                {
                  if (mode === '全场' || mode === '半场') {
                    if (data.category) {
                      switch (data.category) {
                        case '让球':
                          url = '/BasketBall/LetRealTime?id=' + id + '&mode=' + mode + '&freqTCWarning=' + warning;
                          break;
                        case '大小':
                          url = '/BasketBall/SizeRealTime?id=' + id + '&mode=' + mode + '&freqTCWarning=' + warning;
                          break;
                      }
                    }
                    if (url !== '') {
                      this.openNewWindow(url);
                      if (monitor.volNotice) {
                        this.playWarningAudio();
                      }
                    }
                  }
                  break;
                }
              default:
                {
                  debugger;
                  console.error("MonitorType ", monitor.monitorType, " is not supported!");
                  console.error(monitor);
                }
            }
          }
        }
      }
    },
    monitorCallBack: function monitorCallBack(data) {
      if (this.watchScroll) {
        console.log(data);
        if (data.category) {
          var url = '';
          switch (data.category) {
            case '让球':
              url = '/BasketBall/LetRealTime?id=' + data.id + '&mode=' + data.mode + '&mid=' + data.monitorId;
              this.showNotify(data.type, data.description, url);
              break;
            case '大小':
              url = '/BasketBall/SizeRealTime?id=' + data.id + '&mode=' + data.mode + '&mid=' + data.monitorId;
              this.showNotify(data.type, data.description, url);
              break;
          }
        }
      }
    },
    bindwarningFloatSize: function bindwarningFloatSize() {
      var ins = this;
      window.onresize = function () {
        if (ins.basketball.mode === 0 && ins.basketball.today.config.floatWarning) {
          ins.basketballToDayWarningResize++;
          ins.basketball.today.config.floatWarning = false;
          ins.$nextTick(function () {
            ins.basketball.today.config.floatWarning = true;
          });
        }
        if (ins.hedging.mode === 0 && ins.hedging.config.floatWarning) {
          ins.hedgingWarningResize++;
          ins.hedging.config.floatWarning = false;
          ins.$nextTick(function () {
            ins.hedging.config.floatWarning = true;
          });
        }
      };
    },
    spiltBasketball: function spiltBasketball(array, mode, t, i) {
      if (array && array.length > 0) {
        var item = array.find(function (x) {
          return x.mode === mode;
        });
        if (item) {
          var f1 = "".concat(t, "o").concat(i);
          var f2 = "".concat(t, "v").concat(i);
          var v1 = item[f1];
          var v2 = item[f2];
          if (v2 <= 0) {
            if (v1 === undefined) {
              return '';
            }
            return v1.toFixed(3);
          }
          if (v1 == undefined && v2 === undefined) {
            return '';
          }
          if (v1 === undefined) {
            return "".concat(v2.toFixed(1));
          }
          if (v2 === undefined) {
            return "".concat(v1.toFixed(3));
          }
          return "".concat(v1.toFixed(3), " \uFF5C ").concat(v2.toFixed(1));
        }
      }
      return '';
    },
    spiltBet188: function spiltBet188(item, bet188, mode, rb, i) {
      var array = bet188 ? item.lasts : item.basketball.lasts;
      if (array && array.length > 0) {
        var _item = array.find(function (x) {
          return x.mode == mode;
        });
        if (_item) {
          var f1 = "".concat(rb, "v").concat(i, "Odds");
          var f2 = "".concat(rb, "v").concat(i);
          var v1 = _item[f1];
          var v2 = _item[f2];
          if (bet188 && rb === 'r') {
            v2 = -v2;
          }
          if (v2 < 0) {
            if (v1 === undefined) {
              return '';
            }
            //统计信息展示
            return "".concat(v1.toFixed(3));
          }
          if (v1 == undefined && v2 === undefined) {
            return '';
          }
          if (v1 === undefined) {
            return "".concat(v2.toFixed(1));
          }
          if (v2 === undefined) {
            return "".concat(v1.toFixed(3));
          }
          if (rb === 'b' && i === 2) {
            //统计信息展示
            return "".concat(v1.toFixed(3));
          }
          return "".concat(v1.toFixed(3), " \uFF5C ").concat(v2.toFixed(1));
        }
      }
      return '';
    },
    spiltBet188Measure: function spiltBet188Measure(item, bet188, mode, rb, i, v) {
      var array = bet188 ? item.measures : item.basketball.measures;
      var org = item;
      if (array && array.length > 0) {
        var _item2 = array.find(function (x) {
          return x.mode == mode;
        });
        if (_item2) {
          var f1 = "".concat(rb, "v").concat(i, "OddsUpTimes");
          var f2 = "".concat(rb, "v").concat(i, "OddsDownTimes");
          var f3 = "".concat(rb, "v").concat(i, "OddsUp");
          var f4 = "".concat(rb, "v").concat(i, "OddsDown");
          var v1 = _item2[f1];
          var v2 = _item2[f2];
          var v3 = _item2[f3];
          var v4 = _item2[f4];
          if (v) {
            var r = '<div style="padding:15px;">';
            if (v3 !== undefined && v3 !== null && v3 !== 'undefined' && !Number.isNaN(v3)) {
              r += "\u6700\u5927\u6DA8\u5E45:<span style='color:red;margin-left:5px;font-weight:blod;'>".concat(v3.toFixed(3), "</span>&nbsp;&nbsp;");
            } else {
              r += "\u6700\u5927\u6DA8\u5E45:<span style='color:red;margin-left:5px;font-weight:blod;'>\u6682\u65E0\u6570\u636E</span>&nbsp;&nbsp;";
            }
            if (v4 !== undefined && v4 !== null && v4 !== 'undefined' && !Number.isNaN(v4)) {
              r += "\u6700\u5927\u8DCC\u5E45:<span style='color:green;margin-left:5px;font-weight:blod;'>".concat(v4.toFixed(3), "</span>&nbsp;&nbsp;");
            } else {
              r += "\u6700\u5927\u8DCC\u5E45:<span style='color:green;margin-left:5px;font-weight:blod;'>\u6682\u65E0\u6570\u636E</span>&nbsp;&nbsp;";
            }
            r += '</div>';
            return r;
          }
          if (!v1) v1 = 0;
          if (!v2) v2 = 0;
          if (this.spiltBet188(org, bet188, mode, rb, i) !== '') {
            return "<span><span style='color:red;'>".concat(v1, "</span> - <span style='color:green;'>").concat(v2, "</span></span>");
          }
        }
      }
      return '';
    },
    buildBet188LetSize: function buildBet188LetSize(item, bet188) {
      var s1 = "<span style=\"color:red;\">\u5927\u5206</span>";
      var s2 = "<span style=\"color:green;\">\u5C0F\u5206</span>";
      var s3 = "<span style=\"color:red;\">\u8BA9\u7403</span>";
      var s4 = "<span style=\"color:green;\">\u53D7\u8BA9</span>";
      if (bet188) {
        if (item.letSize !== 0) {
          //大分、小分
          if (item.category === 0) {
            return s1;
          }
          if (item.category === 1) {
            return s2;
          }
        }
        if (item.letSize === 0) {
          if (item.betValue < 0) {
            return s3;
          }
          if (item.betValue > 0) {
            return s4;
          }
        }
      } else {
        if (item.letSize !== 0) {
          //大分、小分
          if (item.category === 0) {
            return s2;
          }
          if (item.category === 1) {
            return s1;
          }
        }
        if (item.letSize === 0) {
          if (item.startValue < 0) {
            return s3;
          }
          if (item.startValue > 0) {
            return s4;
          }
        }
      }
      return '';
    },
    computeWarning: function computeWarning(data) {
      if (data.length <= 0) {
        return;
      }
      var ins = this;
      if (ins.basketball.today.list && ins.basketball.today.list.length > 0) {
        var flag = false;
        var config = Object.assign({}, ins.basketball.today.config);
        data.forEach(function (ite) {
          var old = ins.basketball.today.list.find(function (x) {
            return x.id === ite.id;
          });
          if (!old) {
            return;
          }
          var d1 = [];
          var d2 = [];
          var _loop = function _loop(i) {
            var m1 = ite.data.d2.find(function (x) {
              return x.mode === i;
            });
            var m2 = old.data.d2.find(function (x) {
              return x.mode === i;
            });
            if (!m1 || !m2) {
              return 1; // continue
            }
            var rq1 = false;
            var rq2 = false;
            var dx1 = false;
            var dx2 = false;
            if (config["rv".concat(i)]) {
              var k = "rv".concat(i);
              if (isNaN(config[k]) || config[k] <= 0) {
                config[k] = i === 0 ? 3 : 1.5;
              }
              //让球队1
              if (Math.abs(m1.rv1 - m2.rv1) >= config[k]) {
                //ok 报警
                rq1 = true;
              }
              //让球队2
              if (Math.abs(m1.rv2 - m2.rv2) >= config[k]) {
                //ok 报警
                rq2 = true;
              }
            }
            if (config["bv".concat(i + 1)]) {
              var _k = "bv".concat(i + 1);
              if (isNaN(config[_k]) || config[_k] <= 0) {
                config[_k] = i === 0 ? 3 : 1.5;
              }
              //大小队1
              if (Math.abs(m1.bv1 - m2.bv1) >= config[_k]) {
                //ok 报警
                dx1 = true;
              }
              //大小队2
              if (Math.abs(m1.bv2 - m2.bv2) >= config[_k]) {
                //ok 报警
                dx2 = true;
              }
            }
            if (rq1 || rq2 || dx1 || dx2) {
              var c1 = cloneDeep(m1);
              var c2 = cloneDeep(m2);
              if (!rq1) {
                c1.rv1 = undefined;
                c2.rv1 = undefined;
                c1.ro1 = undefined;
                c2.ro1 = undefined;
              }
              if (!rq2) {
                c1.rv2 = undefined;
                c2.rv2 = undefined;
                c1.ro2 = undefined;
                c2.ro2 = undefined;
              }
              if (!dx1) {
                c1.bv1 = undefined;
                c2.bv1 = undefined;
                c1.bo1 = undefined;
                c2.bo1 = undefined;
              }
              if (!dx2) {
                c1.bv2 = undefined;
                c2.bv2 = undefined;
                c1.bo2 = undefined;
                c2.bo2 = undefined;
              }
              d2.push(c1);
              d1.push(c2);
            }
          };
          for (var i = 0; i < 2; i++) {
            if (_loop(i)) continue;
          }
          if (d1.length > 0 && d2.length > 0) {
            var copy = cloneDeep(Object.assign({
              time: new Date()
            }, ite));
            copy.data.d0 = [];
            copy.data.d1 = d1;
            copy.data.d2 = d2;
            ins.basketball.today.warnings.splice(0, 0, copy);
            flag = true;
          }
        });
        if (flag) {
          ins.warningSelectIndex = -1;
          if (ins.current && ins.current.code !== 'Basketball') {
            ins.warningNotRead++;
          }
        }
      }
    },
    startQueryBasketball: function startQueryBasketball() {
      var ins = this;
      if (!ins.watchBasketBall) {
        return;
      }
      if (ins.basketballTimer) {
        clearInterval(ins.basketballTimer);
        ins.basketballTimer = undefined;
      }
      if (ins.basketball.today.config.autoRefresh) {
        var interval = ins.basketball.today.config ? parseInt(ins.basketball.today.config.refreshInterval * 1000) : 15000;
        if (isNaN(interval) || interval <= 3000) {
          interval = 15000;
        }
        ins.basketballTimer = setInterval(function () {
          ins.queryBasketball();
        }, interval);
      }
      ins.queryBasketball();
    },
    startQueryBasketballScroll: function startQueryBasketballScroll() {
      var ins = this;
      if (!ins.watchBasketBall) {
        return;
      }
      if (ins.watchScroll) {
        if (ins.basketballScrollTimer) {
          clearInterval(ins.basketballScrollTimer);
          ins.basketballScrollTimer = undefined;
        }
        if (ins.basketball.scroll.config.autoRefresh) {
          var interval = ins.basketball.scroll.config ? parseInt(ins.basketball.scroll.config.refreshInterval * 1000) : 15000;
          if (isNaN(interval) || interval <= 3000) {
            interval = 15000;
          }
          ins.basketballScrollTimer = setInterval(function () {
            ins.queryBasketballScroll();
          }, interval);
        }
        ins.queryBasketballScroll();
      }
    },
    isPerNumber: function isPerNumber(v) {
      if (isNaN(v)) return false;
      var value = parseFloat(v);
      if (value >= 0 && value <= 100 || value <= 0 && value >= -100) {
        return true;
      }
      return false;
    },
    renderScrollBasketBall: function renderScrollBasketBall(item) {
      if (item && item.id) {
        if (!this.basketball.scroll.config.skipView) {
          this.basketball.scroll.config.skipView = '1';
          return true;
        }
        if (this.basketball.scroll.config.skipView === '1') {
          //看全部
          return true;
        }
        if (this.basketball.scroll.config.skipView === '2') {
          if (!this.basketball.scroll.config.skips) {
            this.basketball.scroll.config.skips = [];
            return true;
          }
          if (this.basketball.scroll.config.skips.length === 0) {
            return true;
          }
          if (item.basketball && item.basketball.id) {
            var find = this.basketball.scroll.config.skips.find(function (x) {
              return x === item.basketball.id;
            });
            if (find) {
              return false;
            }
          }
        }
      }
      return true;
    },
    isInSkips: function isInSkips(item) {
      if (!this.basketball.scroll.config.skips) {
        this.basketball.scroll.config.skips = [];
        return false;
      }
      if (item.basketball && item.basketball.id) {
        var find = this.basketball.scroll.config.skips.find(function (x) {
          return x === item.basketball.id;
        });
        if (find) {
          return true;
        }
      }
      return false;
    },
    addSkips: function addSkips(item) {
      if (!this.basketball.scroll.config.skips) {
        this.basketball.scroll.config.skips = [];
      }
      if (this.basketball.scroll.config.skips.length >= 80) {
        this.basketball.scroll.config.skips.splice(0, 1);
      }
      if (item.basketball && item.basketball.id) {
        this.basketball.scroll.config.skips.push(item.basketball.id);
        this.basketball.scroll.config.skipView = '2';
      }
    },
    delSkips: function delSkips(item) {
      if (!this.basketball.scroll.config.skips) {
        this.basketball.scroll.config.skips = [];
        return;
      }
      if (item.basketball && item.basketball.id) {
        var index = this.basketball.scroll.config.skips.findIndex(function (x) {
          return x === item.basketball.id;
        });
        if (index >= 0) {
          this.basketball.scroll.config.skips.splice(index, 1);
        }
      }
    },
    removeSkips: function removeSkips(data) {
      var ins = this;
      if (ins.basketball.scroll.config.skips.length === 0) {
        return;
      }
      var index = -1;
      var _loop2 = function _loop2() {
        var id = ins.basketball.scroll.config.skips[x];
        var find = data.find(function (x) {
          return x && x.basketball && x.basketball.id === id;
        });
        if (find) {
          return 1; // continue
        }
        index = x;
      };
      for (var x = 0; x < ins.basketball.scroll.config.skips.length; x++) {
        if (_loop2()) continue;
      }
      if (index === -1) {
        return;
      }
      ins.basketball.scroll.config.skips.splice(index, 1);
      debugger;
      ins.removeSkips(data);
    },
    computeScrollWarning: function computeScrollWarning(data) {
      if (data.length <= 0) {
        return;
      }
      var ins = this;
      if (ins.basketball.scroll.list && ins.basketball.scroll.list.length > 0) {
        var flag = false;
        var config = Object.assign({}, ins.basketball.scroll.config);
        ins.removeSkips(data);
        data.forEach(function (ite) {
          if (!ite.id || typeof ite.id === 'undefined' || ite.id === undefined) {
            return;
          }
          var old = ins.basketball.scroll.warnings.find(function (x) {
            return x.pid === ite.pid;
          });
          var matched = false;
          if (ins.isPerNumber(config.ov1) && ins.isPerNumber(ite.ov1)) {
            var v1 = parseFloat(config.ov1);
            var v2 = parseFloat(ite.ov1);
            if (v2 >= v1) {
              //检测之前的数据
              if (old && ins.isPerNumber(old.ov1)) {
                var v3 = parseFloat(old.ov1);
                if (v3 >= v1 && v3 < v2) {
                  matched = true;
                }
              } else {
                matched = true;
              }
            }
          }
          if (ins.isPerNumber(config.ov2) && ins.isPerNumber(ite.ov2)) {
            var _v = parseFloat(config.ov2);
            var _v2 = parseFloat(ite.ov2);
            if (_v2 >= _v) {
              //检测之前的数据
              if (old && ins.isPerNumber(old.ov2)) {
                var _v3 = parseFloat(old.ov2);
                if (_v3 >= _v && _v3 < _v2) {
                  matched = true;
                }
              } else {
                matched = true;
              }
            }
          }
          if (ins.isPerNumber(config.un1) && ins.isPerNumber(ite.un1)) {
            var _v4 = parseFloat(config.un1);
            var _v5 = parseFloat(ite.un1);
            if (_v5 <= -_v4) {
              //检测之前的数据
              if (old && ins.isPerNumber(old.un1)) {
                var _v6 = parseFloat(old.un1);
                if (_v6 <= -_v4 && _v6 > _v5) {
                  matched = true;
                }
              } else {
                matched = true;
              }
            }
          }
          if (ins.isPerNumber(config.un2) && ins.isPerNumber(ite.un2)) {
            var _v7 = parseFloat(config.un2);
            var _v8 = parseFloat(ite.un2);
            if (_v8 <= -_v7) {
              //检测之前的数据
              if (old && ins.isPerNumber(old.un2)) {
                var _v9 = parseFloat(old.un2);
                if (_v9 <= -_v7 && _v9 > _v8) {
                  matched = true;
                }
              } else {
                matched = true;
              }
            }
          }
          switch (matched) {
            case true:
              {
                if (flag === false && ins.basketball.scroll.warnings.length > 0) {
                  ins.basketball.scroll.warnings.forEach(function (o) {
                    if (o.last === true) {
                      o.last = false;
                    }
                  });
                }
                var copy = cloneDeep(Object.assign({
                  time: new Date(),
                  last: true
                }, ite));
                ins.basketball.scroll.warnings.splice(0, 0, copy);
                flag = true;
                break;
              }
            default:
              break;
          }
        });
        if (flag) {
          ins.warningSelectIndex = -1;
          if (ins.current && ins.current.code !== 'Basketball') {
            ins.warningNotRead++;
          }
        }
      }
    },
    isLastScrollWarning: function isLastScrollWarning(pid) {
      var find = this.basketball.scroll.warnings.find(function (x) {
        return x.pid === pid && x.last === true;
      });
      if (find) {
        return true;
      }
      return false;
    },
    openSize: function openSize(item, mode) {
      var type = '';
      switch (mode) {
        case '全场':
          if (this.isPerNumber(item.ov1)) {
            type = '高位';
          }
          if (this.isPerNumber(item.un1)) {
            type = '低位';
          }
          break;
        case '半场':
          if (this.isPerNumber(item.ov2)) {
            type = '高位';
          }
          if (this.isPerNumber(item.un2)) {
            type = '低位';
          }
          break;
      }
      if (type !== '') {
        var url = '/BasketBall/Size?id=' + item.id + '&type=' + type + '&mode=' + mode;
        this.openNewWindow(url);
      }
    },
    openLet: function openLet(item, mode) {
      var url = '/BasketBall/Let?id=' + item.id + '&mode=' + mode;
      this.openNewWindow(url);
    },
    openSizeWindow: function openSizeWindow() {
      var url = '/BasketBall/Size?id=000000000000000000000000000000';
      this.openNewWindow(url);
    },
    openLetWindow: function openLetWindow() {
      var url = '/BasketBall/Let?id=000000000000000000000000000000';
      this.openNewWindow(url);
    },
    /**
     * 滚球数据
     * */
    queryBasketballScroll: function queryBasketballScroll() {
      var ins = this;
      if (ins.basketballScrollLoading) return;
      ins.basketballScrollLoading = true;
      ins.$api.BasketballScroll().then(function (res) {
        ins.basketballScrollLoading = false;
        if (res && res.data) {
          var id = '';
          if (ins.current && ins.current.code === 'Basketball') {
            if (ins.selectedIndex >= 0) {
              id = ins.basketball.scroll.list[ins.selectedIndex].id;
            }
          }
          ins.basketball.scroll.list = res.data;
          ins.basketball.scroll.time = new Date();
          ins.computeScrollWarning(ins.basketball.scroll.list);
          if (ins.current && ins.current.code === 'Basketball') {
            ins.selectedIndex = -1;
            if (id !== '') {
              for (var x = 0; x < ins.basketball.scroll.list.length; x++) {
                if (ins.basketball.scroll.list[x].id === id) {
                  ins.selectedIndex = x;
                  break;
                }
              }
            }
          }
        }
      }).catch(function (res) {
        ins.basketballScrollLoading = false;
      });
    },
    basketballLowerValue: function basketballLowerValue(val) {
      if (this.isPerNumber(val)) {
        return "".concat(val).toString().replace('-', '');
      }
      return '';
    },
    /**
     * 今日数据
     * */
    queryBasketball: function queryBasketball() {
      var ins = this;
      if (ins.basketballLoading) return;
      ins.basketballLoading = true;
      ins.$api.Basketball().then(function (res) {
        ins.basketballLoading = false;
        if (res && res.data) {
          var id = '';
          if (ins.current && ins.current.code === 'Basketball') {
            if (ins.selectedIndex >= 0) {
              id = ins.basketball.today.list[ins.selectedIndex].id;
            }
          }
          ins.computeWarning(res.data);
          ins.basketball.today.list = res.data;
          ins.basketball.today.time = new Date();
          if (ins.current && ins.current.code === 'Basketball') {
            ins.selectedIndex = -1;
            if (id !== '') {
              for (var x = 0; x < ins.basketball.today.list.length; x++) {
                if (ins.basketball.today.list[x].id === id) {
                  ins.selectedIndex = x;
                  break;
                }
              }
            }
          }
        }
      }).catch(function (res) {
        ins.basketballLoading = false;
      });
    },
    bindwarningAudioEnd: function bindwarningAudioEnd() {
      try {
        var ins = this;
        var warning = document.getElementById("warningAudio");
        warning.onended = function () {
          //标记播放完成
          ins.playing = false;
        };
      } catch (error) {}
    },
    playWarningAudio: function playWarningAudio() {
      try {
        if (this.playing) return;
        //标记正在播放
        this.playing = true;
        // 预警声音播放
        var warning = document.getElementById('warningAudio');
        // 触发播放
        warning.play();
      } catch (error) {}
    },
    bindwarningScrollAudioEnd: function bindwarningScrollAudioEnd() {
      try {
        var ins = this;
        var warning = document.getElementById("warningAudioScroll");
        warning.onended = function () {
          //标记播放完成
          ins.playingScroll = false;
        };
      } catch (error) {}
    },
    playWarningScrollAudio: function playWarningScrollAudio() {
      try {
        if (this.playingScroll || !this.watchScroll) return;
        //标记正在播放
        this.playingScroll = true;
        // 预警声音播放
        var warning = document.getElementById('warningAudioScroll');
        // 触发播放
        warning.play();
      } catch (error) {}
    },
    loadCache: function loadCache() {
      var _this = this;
      this.expriesTime = this.$store.state.d2admin.user.info.expriesTime;
      if (!this.expriesTime) {
        this.expriesTime = '无限期';
      }
      if (this.$store.state.d2admin.user.query) {
        if (this.$store.state.d2admin.user.query.length > 0) {
          var cache = this.$store.state.d2admin.user.query;
          if (cache && cache.length > 0) {
            cache.forEach(function (item) {
              if (item.code === '') {
                item.code = 'Basketball';
              }
              for (var x = 0; x < _this.categories.length; x++) {
                if (_this.categories[x].code == item.code) {
                  _this.categories[x] = Object.assign(_this.categories[x], item);
                  _this.categories[x].list = [];
                  _this.categories[x].fav = false;
                  if (_this.categories[x].refreshTimer !== 0) {
                    _this.refreshTimer(_this.categories[x]);
                  }
                  if (item.code === 'Basketball') {
                    if (item.today) {
                      if (item.today.list) {
                        item.today.list = [];
                      }
                      if (item.today.warnings) {
                        item.today.warnings = [];
                      }
                    }
                    if (item.scroll) {
                      if (item.scroll.list) {
                        item.scroll.list = [];
                      }
                      if (item.scroll.warnings) {
                        item.scroll.warnings = [];
                      }
                    }
                    _this.basketball = Object.assign(_this.basketball, item);
                  }
                  if (item.code === 'hedging') {
                    item.list = [];
                    item.warnings = [];
                    _this.hedging = Object.assign(_this.hedging, item);
                  }
                }
              }
            });
            this.$store.state.d2admin.user.query = this.categories;
          }
          this.categories = this.$store.state.d2admin.user.query;
          for (var x = 0; x < this.categories.length; x++) {
            if (this.categories[x].refreshTimer !== 0) {
              this.refreshTimer(this.categories[x]);
            }
            this.categories[x].fav = false;
            this.categories[x].list = [];
            if (this.categories[x].code === '') {
              this.categories[x].code = 'Basketball';
            }
          }
        }
      }
      for (var _x = 0; _x < this.categories.length; _x++) {
        switch (this.categories[_x].code) {
          case 'Basketball':
            this.categories[_x].per = this.watchBasketBall;
            this.basketball.per = this.watchBasketBall;
            break;
          case 'hedging':
            this.categories[_x].per = this.watchHedging;
            this.hedging.per = this.watchHedging;
            break;
          default:
            this.categories[_x].per = this.watchSurebet;
            break;
        }
      }
    },
    getNotices: function getNotices() {
      var ins = this;
      ins.$api.Notices().then(function (res) {
        if (res && res.data) {
          ins.notices = res.data;
          ins.notice = 0;
          if (ins.noticeTimer) {
            clearInterval(ins.noticeTimer);
          }
          ins.noticeTimer = setInterval(function () {
            ins.updateNotice();
          }, 10000);
        }
      });
    },
    refreshTimer: function refreshTimer(curr) {
      var ins = this;
      if (curr && curr.refreshTimer > 0) {
        setTimeout(function () {
          curr.refreshTimer--;
          ins.refreshTimer(curr);
        }, 1000);
      }
    },
    updateNotice: function updateNotice() {
      var ins = this;
      if (ins.$refs.notice) {
        var scroll = ins.$refs.notice.scrollTop + 30;
        if (scroll < ins.$refs.notice.scrollHeight) {
          ins.$refs.notice.scrollTop = scroll;
          return;
        }
        if (ins.notice + 1 === ins.notices.length) {
          ins.getNotices();
          return;
        }
        ins.$refs.notice.scrollTop = 0;
        ins.notice += 1;
      }
    },
    getPlatforms: function getPlatforms() {
      var ins = this;
      ins.loading = true;
      ins.$api.Platforms().then(function (res) {
        if (res && res.success && res.data) {
          var platforms = [];
          for (var x = 0; x < res.data.length; x++) {
            platforms.push(Object.assign({
              checked: false
            }, res.data[x]));
          }
          var _loop3 = function _loop3() {
            var old = ins.categories[i].platforms;
            var lst = ins.cloneArray(platforms);
            var _loop4 = function _loop4(k) {
              var find = old.find(function (x) {
                return x.code == lst[k].code;
              });
              if (find) {
                lst[k].checked = find.checked;
              }
            };
            for (var k = 0; k < lst.length; k++) {
              _loop4(k);
            }
            ins.categories[i].platforms = lst;
          };
          for (var i = 0; i < ins.categories.length; i++) {
            _loop3();
          }
        }
        ins.total++;
        if (ins.total === 2) {
          ins.loading = false;
        }
      }).catch(function (res) {
        ins.total++;
        if (ins.total === 2) {
          ins.loading = false;
        }
      });
    },
    getSports: function getSports() {
      var ins = this;
      ins.$api.Sports().then(function (res) {
        if (res && res.success && res.data) {
          var gmeSports = [];
          var tysSports = [];
          for (var x = 0; x < res.data.length; x++) {
            if (res.data[x].game) {
              gmeSports.push(Object.assign({
                checked: false
              }, res.data[x]));
              continue;
            }
            tysSports.push(Object.assign({
              checked: false
            }, res.data[x]));
          }
          var _loop5 = function _loop5() {
            var old = ins.categories[i].tysSports;
            var lst = ins.cloneArray(tysSports);
            var _loop6 = function _loop6(k) {
              var find = old.find(function (x) {
                return x.code == lst[k].code;
              });
              if (find) {
                lst[k].checked = find.checked;
              }
            };
            for (var k = 0; k < lst.length; k++) {
              _loop6(k);
            }
            ins.categories[i].tysSports = lst;
            var old2 = ins.categories[i].gmeSports;
            var lst2 = ins.cloneArray(gmeSports);
            var _loop7 = function _loop7(_k2) {
              var find = old2.find(function (x) {
                return x.code == lst2[_k2].code;
              });
              if (find) {
                lst2[_k2].checked = find.checked;
              }
            };
            for (var _k2 = 0; _k2 < lst2.length; _k2++) {
              _loop7(_k2);
            }
            ins.categories[i].gmeSports = lst2;
          };
          for (var i = 0; i < ins.categories.length; i++) {
            _loop5();
          }
        }
        ins.total++;
        if (ins.total === 2) {
          ins.loading = false;
        }
      }).catch(function (res) {
        ins.total++;
        if (ins.total === 2) {
          ins.loading = false;
        }
      });
    },
    cloneArray: function cloneArray(array) {
      var result = [];
      for (var x = 0; x < array.length; x++) {
        result.push(Object.assign({}, array[x]));
      }
      return result;
    },
    goPage: function goPage(type) {
      var condition = {
        cursor: undefined
      };
      var ins = this;
      if (!ins.checkRefreshTimer()) {
        return;
      }
      switch (type) {
        case 'preview':
          condition.cursor = this.previewCursor;
          break;
        case 'next':
          condition.cursor = this.nextCursor;
          break;
        default:
          break;
      }
      if (condition.cursor) {
        var func = ins.$api[ins.current.code];
        if (func) {
          ins.loading = true;
          ins.$refs.content.scrollTop = 0;
          func(condition).then(function (res) {
            ins.current.list = [];
            if (res && res.success && res.data) {
              ins.current.list = res.data;
            }
            ins.loading = false;
            ins.current.refreshTimer = 3;
            ins.refreshTimer(ins.current);
          }).catch(function (res) {
            ins.loading = false;
            ins.current.list = [];
          });
        }
      }
    },
    selectedItem: function selectedItem(index) {
      this.selectedIndex = index;
      return true;
    },
    setCache: function setCache() {
      var save = [];
      for (var x = 0; x < this.categories.length; x++) {
        var item = cloneDeep(Object.assign({
          list: []
        }, this.categories[x]));
        if (item) {
          if (item.list) {
            item.list = [];
          }
          if (item.warnings) {
            item.warnings = [];
          }
          if (item.today) {
            if (item.today.list) {
              item.today.list = [];
            }
            if (item.today.warnings) {
              item.today.warnings = [];
            }
          }
          if (item.scroll) {
            if (item.scroll.list) {
              item.scroll.list = [];
            }
            if (item.scroll.warnings) {
              item.scroll.warnings = [];
            }
          }
        }
        save.push(item);
      }
      this.setQuery(save);
    },
    query: function query() {
      var ins = this;
      if (!ins.checkRefreshTimer()) {
        return;
      }
      if (ins.current.fav) {
        //查询收藏的数据
        ins.loading = true;
        ins.$refs.content.scrollTop = 0;
        ins.$api.BssSurebetFavMy({
          module: ins.current.text
        }).then(function (res) {
          ins.current.list = [];
          ins.setCache();
          if (res && res.success && res.data) {
            ins.current.list = res.data;
          }
          ins.current.refreshTimer = 3;
          ins.refreshTimer(ins.current);
          ins.loading = false;
        }).catch(function (res) {
          ins.current.list = [];
          ins.loading = false;
        });
        return;
      }
      if (ins.current.listQuery.sports.length === 0) {
        ins.$message.error('请选择体育赛事或竞技赛事');
        return;
      }
      if (ins.current.listQuery.platforms.length === 0) {
        ins.$message.error('请选择平台');
        return;
      }
      var func = ins.$api[ins.current.code];
      if (func) {
        ins.loading = true;
        ins.$refs.content.scrollTop = 0;
        func(ins.current.listQuery).then(function (res) {
          ins.current.list = [];
          ins.setCache();
          if (res && res.success && res.data) {
            ins.current.list = res.data;
          }
          ins.current.refreshTimer = 3;
          ins.refreshTimer(ins.current);
          ins.loading = false;
        }).catch(function (res) {
          ins.current.list = [];
          ins.loading = false;
        });
      }
    },
    timeSpilts: function timeSpilts(time, index) {
      var times = time.split(' ');
      if (times.length >= index + 1) {
        return times[index];
      }
      return '';
    },
    switchCategory: function switchCategory(item) {
      var ins = this;
      if (item.code === '') {
        ins.$message.error('全新盈利模式，敬请期待!');
        return;
      }
      if (item.code === 'Basketball') {
        ins.warningNotRead = 0;
        ins.startAudio();
      }
      if (item.code === 'hedging') {
        ins.hedgingWarningNotRead = 0;
        ins.startAudio();
      }
      ins.current = item;
      ins.warningSelectIndex = -1;
      ins.selectedIndex = -1;
      if (ins.current.code === 'CrownArea') {
        for (var x = 0; x < ins.current.platforms.length; x++) {
          if (ins.current.platforms[x].code === 66) {
            ins.current.platforms[x].checked = true;
            break;
          }
        }
      }
    },
    startAudio: function startAudio() {
      if (this.audioInit) return;
      this.audioInit = true;
      this.playing = false;
      this.playingScroll = false;
      try {
        // 获取元素标签
        var warning1 = document.getElementById('warningAudio');
        // 触发交互
        warning1.pause();
      } catch (error) {}
      try {
        // 获取元素标签
        var warning2 = document.getElementById('warningAudioScroll');
        // 触发交互
        warning2.pause();
      } catch (error) {}
    },
    platformCheckd: function platformCheckd(item) {
      var ins = this;
      if (ins.current.code === 'CrownArea') {
        if (item.code === 66) {
          return;
        }
      }
      item.checked = !item.checked;
    },
    allPlatformChecked: function allPlatformChecked() {
      this.current.allPlatformChecked = !this.current.allPlatformChecked;
      for (var x = 0; x < this.current.platforms.length; x++) {
        if (this.current.code === 'CrownArea') {
          if (this.current.platforms[x].code === 66) {
            if (!this.current.allPlatformChecked) {
              continue;
            }
          }
        }
        this.current.platforms[x].checked = this.current.allPlatformChecked;
      }
    },
    allTysSportChecked: function allTysSportChecked() {
      this.current.allTysSportChecked = !this.current.allTysSportChecked;
      for (var x = 0; x < this.current.tysSports.length; x++) {
        this.current.tysSports[x].checked = this.current.allTysSportChecked;
      }
    },
    allGmeSportChecked: function allGmeSportChecked() {
      this.current.allGmeSportChecked = !this.current.allGmeSportChecked;
      for (var x = 0; x < this.current.gmeSports.length; x++) {
        this.current.gmeSports[x].checked = this.current.allGmeSportChecked;
      }
    },
    scrollBottom: function scrollBottom() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.$refs.content.scrollTop = _this2.$refs.content.scrollHeight;
      });
    },
    scrollTop: function scrollTop() {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs.content.scrollTop = 0;
      });
    },
    copyBasketball: function copyBasketball(item) {
      var ins = this;
      var clip = new Clipboard('.basketball-team-1', {
        text: function text() {
          return item.team1;
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(主队名)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copyBetWarningTeam: function copyBetWarningTeam(name) {
      var ins = this;
      var clip = new Clipboard('.bet188-team-1', {
        text: function text() {
          return name;
        }
      });
      clip.on('success', function () {
        ins.$message.success("\u5DF2\u590D\u5236 ".concat(name));
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copyBasketballGame: function copyBasketballGame(item) {
      var ins = this;
      var clip = new Clipboard('.basketball-game-1', {
        text: function text() {
          return item.name;
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(赛事)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copyBet188: function copyBet188(item) {
      var ins = this;
      var clip = new Clipboard('.basketball-team-1', {
        text: function text() {
          return item.basketball.team1;
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(主队名)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copyBet188Game: function copyBet188Game(item) {
      var ins = this;
      var clip = new Clipboard('.basketball-game-1', {
        text: function text() {
          return item.basketball.name;
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(赛事)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copyTeam: function copyTeam(child) {
      var ins = this;
      var val = ins.lang === 'zh' ? child.team : child.teamEn;
      var clip = new Clipboard('.container-left-body-content-item-project-item-up', {
        text: function text() {
          var arr = val.split('-');
          return arr[0];
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(主队名)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    copySport: function copySport(child) {
      var ins = this;
      var val = ins.lang === 'zh' ? child.game : child.gameEn;
      var clip = new Clipboard('.container-left-body-content-item-project-item-down', {
        text: function text() {
          return val;
        }
      });
      clip.on('success', function () {
        ins.$message.success('已复制到剪贴板(赛事)');
        clip.destroy();
      });
      clip.on('error', function () {
        ins.$message.error('您的浏览器不支持自动复制,请手动复制！');
        clip.destroy();
      });
    },
    openCalculator: function openCalculator(index) {
      window.open("/calculator/rule".concat(index, ".html"), '_blank');
    },
    openCalculatorRule: function openCalculatorRule(odds1, odds2) {
      window.open("/calculator/rule1.html?odds1=".concat(odds1, "&odds2=").concat(odds2), '_blank');
    },
    checkRefreshTimer: function checkRefreshTimer() {
      var ins = this;
      if (ins.current.refreshTimer > 0) {
        ins.$message.error("\u8BF7\u5728".concat(ins.current.refreshTimer, "\u79D2\u540E\u518D\u8BD5!"));
        return false;
      }
      return true;
    },
    openSizeRealTime: function openSizeRealTime(item, mode) {
      if (this.watchScroll) {
        var id = item.id;
        var url = "/BasketBall/SizeRealTime?id=".concat(id, "&mode=").concat(mode);
        this.openNewWindow(url);
      }
    },
    openLetRealTime: function openLetRealTime(item, mode) {
      if (this.watchScroll) {
        var id = item.id;
        var url = "/BasketBall/LetRealTime?id=".concat(id, "&mode=").concat(mode);
        this.openNewWindow(url);
      }
    },
    openHistory: function openHistory(item, mode, type) {
      if (this.watchBasketBallHis) {
        var id = item.id;
        //this.openNewWindow(`/history/Show?id=${id}&type=${type}`);
        this.openNewWindow("/BasketBall/LowerHeight?id=".concat(id, "&type=").concat(type, "&mode=").concat(mode));
      }
    },
    openNewWindow: function openNewWindow(url) {
      var w = window.innerWidth * 0.8 || 1250;
      var h = window.innerHeight * 0.8 || 650;
      if (window.location.host.toString().indexOf('localhost') >= 0) {
        url = 'http://localhost:8036' + url;
      }
      if (url.indexOf('Size?') >= 0 || url.indexOf('Let?') >= 0) {
        if (w !== 1250) {
          w = 1250;
        }
        if (h !== 450) {
          h = 450;
        }
      }
      if (url.indexOf('SizeRealTime?') >= 0 || url.indexOf('LetRealTime?') >= 0) {
        if (w !== 1315) {
          w = 1315;
        }
        if (h !== 1065) {
          h = 1065;
        }
      }
      if (url.indexOf('LowerHeight?') >= 0) {
        if (w !== 1315) {
          w = 1315;
        }
        if (h !== 670) {
          h = 670;
        }
      }
      var t = (window.screen.height - 30 - h) / 2 || 100;
      var l = (window.screen.width - 10 - w) / 2 || 100;
      if (l < 0) {
        l = 0;
      }
      if (t < 0) {
        t = 0;
      }
      var option = 'height=' + h + ',,innerHeight=' + h + ',width=' + w + ',innerWidth=' + w + ',top=' + t + ',left=' + l + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no';
      window.open(url, '_blank', option);
    },
    queryCalclator: function queryCalclator(item) {
      var ins = this;
      if (!ins.checkRefreshTimer()) {
        return;
      }
      ins.loading = true;
      ins.$api.Calculator({
        calculator: item.calculator
      }).then(function (res) {
        ins.loading = false;
        ins.current.refreshTimer = 3;
        ins.refreshTimer(ins.current);
        if (res && res.success && res.data && res.data.length === 1) {
          var id = res.data[0].calculator;
          ins.openNewWindow("/calculator/Show?id=".concat(id));
        }
      }).catch(function (res) {
        ins.loading = false;
      });
    },
    favItem: function favItem(item, flag) {
      var ins = this;
      item.fav = flag;
      if (item.fav) {
        ins.$api.BssSurebetFavAddOneEx({
          rid: item.id,
          content: JSON.stringify(item),
          module: ins.current.text
        }).then(function (res) {
          if (res && res.success && res.data) {
            ins.$message.success('已成功收藏!');
          }
        });
        return;
      }
      ins.$api.BssSurebetFavCacnel([item.id]).then(function (res) {
        if (res && res.success && res.data) {
          ins.$message.success('已取消收藏!');
        }
      });
    },
    havePermission: function havePermission(code) {
      var store = this.$store;
      if (store.state && store.state.d2admin && store.state.d2admin.user && store.state.d2admin.user.info.funcs) {
        var find = store.state.d2admin.user.info.funcs.find(function (x) {
          return x.code === code;
        });
        if (find) return true;
        return false;
      }
      return undefined;
    },
    uuid: function uuid() {
      var store = this.$store;
      if (store.state && store.state.d2admin && store.state.d2admin.user && store.state.d2admin.user.info.funcs) {
        return store.state.d2admin.user.info.id;
      }
      return undefined;
    }
  }),
  computed: {
    /**
     * 查看篮球历史数据
     */
    watchBasketBallHis: function watchBasketBallHis() {
      return this.watchScroll;
    },
    /**
     * 查看对冲模块
     */
    watchHedging: function watchHedging() {
      if (this.havePermission('customer:hedging')) {
        return true;
      }
      return false;
    },
    /**
     * 查看滚球数据
     */
    watchScroll: function watchScroll() {
      return this.watchBasketBall;
    },
    /**
     * 查看篮球模块
     */
    watchBasketBall: function watchBasketBall() {
      if (this.havePermission('customer:basketball')) {
        return true;
      }
      return false;
    },
    /**
     * 只看篮球模块
     */
    onlyWatchBasketBall: function onlyWatchBasketBall() {
      return this.watchSurebet === false && this.watchBasketBall === true && this.watchHedging === false;
    },
    /**
     * 只看篮球模块
     */
    onlyWatchHedging: function onlyWatchHedging() {
      return this.watchSurebet === false && this.watchBasketBall === false && this.watchHedging === true;
    },
    /**
     * 只看对冲模块
     */
    onlyWatchSurebet: function onlyWatchSurebet() {
      return this.watchSurebet === true && this.watchBasketBall === false && this.watchHedging === false;
    },
    /**
     * 查看套利模块
     */
    watchSurebet: function watchSurebet() {
      if (this.havePermission('customer:surebet')) {
        return true;
      }
      return false;
    },
    hedgingWarningHeight: function hedgingWarningHeight() {
      var _this4 = this;
      if (this.hedging.mode === 0 && this.hedging.warnings && this.hedging.warnings.length > 0 && this.hedging.config.floatWarning && this.hedgingWarningResize >= 0) {
        var val = 25 + this.hedging.warnings.length * 50;
        if (this.$refs.hedgingWarning) {
          if (val < this.$refs.hedgingWarning.scrollHeight) {
            val = this.$refs.hedgingWarning.scrollHeight;
          }
        }
        if (val > 250) {
          return 'height:250px;';
        }
        this.$nextTick(function () {
          if (_this4.$refs.hedgingWarning) {
            if (val != _this4.$refs.hedgingWarning.scrollHeight) {
              _this4.hedgingWarningResize++;
            }
          }
        });
        return "height:".concat(val, "px;");
      }
      return '';
    },
    todayWarningHeight: function todayWarningHeight() {
      var _this5 = this;
      if (this.basketball.mode === 0 && this.basketball.today.warnings && this.basketball.today.warnings.length > 0 && this.basketball.today.config.floatWarning && this.basketballToDayWarningResize >= 0) {
        var val = 25 + this.basketball.today.warnings.length * 50;
        if (this.$refs.basketballToDayWarning) {
          if (val < this.$refs.basketballToDayWarning.scrollHeight) {
            val = this.$refs.basketballToDayWarning.scrollHeight;
          }
        }
        if (val > 250) {
          return 'height:250px;';
        }
        this.$nextTick(function () {
          if (_this5.$refs.basketballToDayWarning) {
            if (val != _this5.$refs.basketballToDayWarning.scrollHeight) {
              _this5.basketballToDayWarningResize++;
            }
          }
        });
        return "height:".concat(val, "px;");
      }
      return '';
    },
    previewCursorEnable: function previewCursorEnable() {
      if (this.change > 0 && this.current && this.current.list && this.current.list.length > 0) {
        if (this.current.list[0].previewCursor && this.current.list[0].previewCursor !== '') {
          return true;
        }
      }
      return false;
    },
    nextCursorEnable: function nextCursorEnable() {
      if (this.change > 0 && this.current && this.current.list && this.current.list.length > 0) {
        if (this.current.list[0].nextCursor && this.current.list[0].nextCursor !== '') {
          return true;
        }
      }
      return false;
    },
    previewCursor: function previewCursor() {
      if (this.change > 0 && this.current && this.current.list && this.current.list.length > 0) {
        if (this.current.list[0].previewCursor && this.current.list[0].previewCursor !== '') {
          return this.current.list[0].previewCursor;
        }
      }
      return undefined;
    },
    nextCursor: function nextCursor() {
      if (this.change > 0 && this.current && this.current.list && this.current.list.length > 0) {
        if (this.current.list[0].nextCursor && this.current.list[0].nextCursor !== '') {
          return this.current.list[0].nextCursor;
        }
      }
      return undefined;
    }
  },
  watch: {
    loading: {
      handler: function handler(val) {
        this.change++;
        this.selectedIndex = -1;
      },
      deep: true,
      immediate: true
    },
    'basketball.today.config.refreshInterval': {
      handler: function handler(val, old) {
        var _this6 = this;
        if (_typeof(val) === undefined) return;
        if (isNaN(val) || parseInt(val) <= 0) {
          this.$nextTick(function () {
            this.basketball.today.config.refreshInterval = 15;
          });
          return;
        }
        if (this.setRefreshIntervalTimer) {
          clearTimeout(this.setRefreshIntervalTimer);
        }
        this.setRefreshIntervalTimer = setTimeout(function () {
          _this6.startQueryBasketball();
        }, 3000);
      },
      deep: true,
      immediate: true
    },
    'basketball.today.config.autoRefresh': {
      handler: function handler(val, old) {
        if (_typeof(old) !== undefined) {
          switch (val) {
            case true:
              this.startQueryBasketball();
              break;
            case false:
              if (this.basketballTimer) {
                clearInterval(this.basketballTimer);
                this.basketballTimer = undefined;
              }
              break;
          }
        }
      },
      deep: true,
      immediate: true
    },
    'basketball.scroll.config.refreshInterval': {
      handler: function handler(val, old) {
        var _this7 = this;
        if (_typeof(val) === undefined) return;
        if (isNaN(val) || parseInt(val) <= 0) {
          this.$nextTick(function () {
            this.basketball.scroll.config.refreshInterval = 15;
          });
          return;
        }
        if (this.setRefreshIntervalTimer) {
          clearTimeout(this.setRefreshIntervalTimer);
        }
        this.setRefreshIntervalTimer = setTimeout(function () {
          _this7.startQueryBasketballScroll();
        }, 3000);
      },
      deep: true,
      immediate: true
    },
    'basketball.scroll.config.autoRefresh': {
      handler: function handler(val, old) {
        if (_typeof(old) !== undefined) {
          switch (val) {
            case true:
              this.startQueryBasketballScroll();
              break;
            case false:
              if (this.basketballScrollTimer) {
                clearInterval(this.basketballScrollTimer);
                this.basketballScrollTimer = undefined;
              }
              break;
          }
        }
      },
      deep: true,
      immediate: true
    },
    'basketball.scroll.config.scoreFullCount': {
      handler: function handler(val, old) {
        if (this.wss && this.watchScroll) {
          this.wss.setScoreWaringParams(0, val);
        }
      },
      deep: true,
      immediate: true
    },
    'basketball.scroll.config.scoreHalfCount': {
      handler: function handler(val, old) {
        if (this.wss && this.watchScroll) {
          this.wss.setScoreWaringParams(1, val);
        }
      },
      deep: true,
      immediate: true
    },
    'basketball.mode': {
      handler: function handler(val, old) {
        this.warningSelectIndex = -1;
        this.selectedIndex = -1;
      },
      deep: true,
      immediate: true
    },
    basketball: {
      handler: function handler(val, old) {
        if (this.current && this.current.code === 'Basketball') {
          for (var x = 0; x < this.categories.length; x++) {
            if (this.categories[x].code === 'Basketball') {
              this.categories[x] = cloneDeep(Object.assign(this.categories[x], this.basketball));
              this.setCache();
              break;
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    'current.lang': {
      handler: function handler(val) {
        if (val) {
          this.lang = val;
        }
      },
      deep: true,
      immediate: true
    },
    'current.platforms': {
      handler: function handler(val) {
        if (val) {
          var selected = [];
          for (var x = 0; x < val.length; x++) {
            if (val[x].checked) {
              selected.push(val[x].code);
            }
          }
          this.current.allPlatformChecked = selected.length == this.current.platforms.length && selected.length > 0;
          this.current.listQuery.platforms = selected;
        }
      },
      deep: true,
      immediate: true
    },
    'current.tysSports': {
      handler: function handler(val) {
        if (val) {
          var selected = [];
          for (var x = 0; x < this.current.gmeSports.length; x++) {
            if (this.current.gmeSports[x].checked) {
              selected.push(this.current.gmeSports[x].code);
            }
          }
          var cnt = 0;
          for (var _x2 = 0; _x2 < val.length; _x2++) {
            if (val[_x2].checked) {
              selected.push(val[_x2].code);
              cnt++;
            }
          }
          this.current.allTysSportChecked = cnt === this.current.tysSports.length && cnt > 0;
          this.current.listQuery.sports = selected;
        }
      },
      deep: true,
      immediate: true
    },
    'current.gmeSports': {
      handler: function handler(val) {
        if (val) {
          var selected = [];
          for (var x = 0; x < this.current.tysSports.length; x++) {
            if (this.current.tysSports[x].checked) {
              selected.push(this.current.tysSports[x].code);
            }
          }
          var cnt = 0;
          for (var _x3 = 0; _x3 < val.length; _x3++) {
            if (val[_x3].checked) {
              selected.push(val[_x3].code);
              cnt++;
            }
          }
          this.current.allGmeSportChecked = this.current.gmeSports.length === cnt && cnt > 0;
          this.current.listQuery.sports = selected;
        }
      },
      deep: true,
      immediate: true
    }
  }
};