import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_vm.loading ? _c("div", {
    staticClass: "container-loading"
  }, [_c("div", {
    staticClass: "container-loading-spinner"
  }, [_c("svg", {
    staticClass: "container-loading-spinner-circular",
    attrs: {
      viewBox: "25 25 50 50"
    }
  }, [_c("circle", {
    staticClass: "container-loading-spinner-circular-path",
    attrs: {
      cx: "50",
      cy: "50",
      r: "20",
      fill: "none"
    }
  })])])]) : _vm._e(), _c("div", {
    class: _vm.current.code === "Basketball" || _vm.current.code === "hedging" ? "container-left container-left-basketball" : "container-left"
  }, [_c("div", {
    staticClass: "container-left-head"
  }, [_vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-head-platform"
  }, [_c("div", {
    staticClass: "container-left-head-platform-title"
  }, [_vm._v("平台选择")]), _c("div", {
    staticClass: "container-left-head-platform-all",
    on: {
      click: _vm.allPlatformChecked
    }
  }, [_c("input", {
    staticClass: "container-left-head-platform-all-input",
    attrs: {
      type: "checkbox",
      disabled: _vm.current.allPlatformChecked
    },
    domProps: {
      checked: _vm.current.allPlatformChecked
    }
  }), _c("span", [_vm._v("全选")])]), _c("div", {
    staticClass: "container-left-head-platform-content"
  }, _vm._l(_vm.current.platforms, function (item, index) {
    return _c("div", {
      key: "pl_" + index,
      class: item.checked ? "container-left-head-platform-content-item container-left-head-platform-content-item-checked" : "container-left-head-platform-content-item",
      on: {
        click: function click($event) {
          return _vm.platformCheckd(item);
        }
      }
    }, [_c("input", {
      staticClass: "container-left-head-platform-content-item-checkbox container-checkbox",
      attrs: {
        type: "checkbox"
      },
      domProps: {
        checked: item.checked ? "checked" : ""
      }
    }), _c("label", {
      staticClass: "checkbox"
    }), _c("label", [_vm._v(_vm._s(item.nameCn))])]);
  }), 0)]) : _c("div", {
    staticClass: "container-left-head-basketball"
  }, [_vm._v("   ")]), _c("div", {
    staticClass: "container-left-head-data"
  }, [_c("div", {
    staticClass: "container-left-head-data-title"
  }, [_vm._v("数据项")]), _c("div", {
    staticClass: "container-left-head-data-content",
    style: _vm.onlyWatchBasketBall || _vm.onlyWatchHedging ? "margin-left:20px;" : ""
  }, [_vm._l(_vm.categories, function (item, index) {
    return [item.per ? _c("div", {
      key: index,
      class: item.text === _vm.current.text ? "container-left-head-data-content-item container-left-head-data-content-item-selected" : "container-left-head-data-content-item",
      on: {
        click: function click($event) {
          return _vm.switchCategory(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " "), item.code === "Basketball" && _vm.warningNotRead > 0 ? _c("i", {
      staticClass: "container-left-head-data-content-item-alert"
    }) : _vm._e(), item.code === "hedging" && _vm.hedgingWarningNotRead > 0 ? _c("i", {
      staticClass: "container-left-head-data-content-item-alert"
    }) : _vm._e()]) : _vm._e()];
  })], 2)]), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-head-lang"
  }, [_c("div", {
    staticClass: "container-left-head-lang-title"
  }, [_vm._v("英汉")]), _c("div", {
    staticClass: "container-left-head-lang-content"
  }, [_c("div", {
    staticClass: "container-left-head-lang-content-body"
  }, [_c("div", {
    class: _vm.lang === "en" ? "container-left-head-lang-content-body-up container-left-head-lang-content-body-selected" : "container-left-head-lang-content-body-up",
    on: {
      click: function click($event) {
        _vm.current.lang = "en";
      }
    }
  }, [_vm._v(" 英")]), _c("div", {
    class: _vm.lang === "zh" ? "container-left-head-lang-content-body-down container-left-head-lang-content-body-selected" : "container-left-head-lang-content-body-down",
    on: {
      click: function click($event) {
        _vm.current.lang = "zh";
      }
    }
  }, [_vm._v(" 汉")])])])]) : _vm._e(), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-head-profit"
  }, [_c("div", {
    staticClass: "container-left-head-profit-up"
  }, [_c("div", {
    staticClass: "container-left-head-profit-up-body"
  }, [_c("div", {
    staticClass: "container-left-head-profit-up-body-icon"
  }), _c("div", {
    staticClass: "container-left-head-profit-up-body-title"
  }, [_c("select", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.current.listQuery.time,
      expression: "current.listQuery.time"
    }],
    staticClass: "container-left-head-profit-up-body-title-select",
    on: {
      change: function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.current.listQuery, "time", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.timeItems, function (item, index) {
    return _c("option", {
      key: "tm_" + index,
      domProps: {
        value: item.key
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)])])]), _c("div", {
    staticClass: "container-left-head-profit-down"
  }, [_c("div", {
    staticClass: "container-left-head-profit-down-body"
  }, [_c("div", {
    staticClass: "container-left-head-profit-down-body-icon"
  }, [_vm._v(_vm._s(_vm.current.valueText) + "(%)")]), _c("div", {
    staticClass: "container-left-head-profit-down-body-title"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.current.listQuery.value,
      expression: "current.listQuery.value"
    }],
    staticClass: "container-left-head-profit-down-body-title-input",
    domProps: {
      value: _vm.current.listQuery.value
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.current.listQuery, "value", $event.target.value);
      }
    }
  })])])])]) : _vm._e(), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-head-lang"
  }, [_c("div", {
    staticClass: "container-left-head-lang-title"
  }, [_vm._v("收藏")]), _c("div", {
    staticClass: "container-left-head-fav-content"
  }, [_c("div", {
    staticClass: "container-left-head-lang-content-body"
  }, [_c("div", {
    class: _vm.current.fav ? "container-left-head-data-content-item container-left-head-data-content-item-selected" : "container-left-head-data-content-item",
    on: {
      click: function click($event) {
        _vm.current.fav = !_vm.current.fav;
      }
    }
  }, [_vm._v(" 我的收藏")])])])]) : _vm._e(), _vm.current.code === "Basketball" ? _c("div", {
    staticClass: "container-left-head-basketball-condition"
  }, [_vm.basketball.mode === 0 ? [_c("div", {
    staticClass: "container-left-head-basketball-condition-title"
  }, [_vm._v("威圖篮球-赛前盘")]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-header"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-refresh",
    attrs: {
      disabled: _vm.basketballLoading
    },
    on: {
      click: function click($event) {
        return _vm.queryBasketball();
      }
    }
  }, [_vm._v("刷新")]), _c("div", {
    class: _vm.basketball.today.config.autoRefresh ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.basketball.today.config.autoRefresh = !_vm.basketball.today.config.autoRefresh;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.today.config.autoRefresh
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("自动刷新")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("间隔")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.today.config.refreshInterval,
      expression: "basketball.today.config.refreshInterval"
    }],
    domProps: {
      value: _vm.basketball.today.config.refreshInterval
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.today.config, "refreshInterval", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("秒")])]), _c("div", {
    class: _vm.basketball.today.config.floatWarning ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.basketball.today.config.floatWarning = !_vm.basketball.today.config.floatWarning;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.today.config.floatWarning
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("浮动报警")])])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-body"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-body-up"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("全场让球+/-")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.today.config.rv1,
      expression: "basketball.today.config.rv1"
    }],
    domProps: {
      value: _vm.basketball.today.config.rv1
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.today.config, "rv1", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("盘口")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("全场大小+/-")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.today.config.bv1,
      expression: "basketball.today.config.bv1"
    }],
    domProps: {
      value: _vm.basketball.today.config.bv1
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.today.config, "bv1", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("盘口")])])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-body-down"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("半场让球+/-")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.today.config.rv2,
      expression: "basketball.today.config.rv2"
    }],
    domProps: {
      value: _vm.basketball.today.config.rv2
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.today.config, "rv2", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("盘口")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("半场大小+/-")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.today.config.bv2,
      expression: "basketball.today.config.bv2"
    }],
    domProps: {
      value: _vm.basketball.today.config.bv2
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.today.config, "bv2", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("盘口")])])])])])] : _vm._e(), _vm.basketball.mode === 1 ? [_c("div", {
    staticClass: "container-left-head-basketball-condition-title"
  }, [_vm._v("威圖篮球-滚球盘")]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-header"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-refresh",
    attrs: {
      disabled: _vm.basketballScrollLoading
    },
    on: {
      click: function click($event) {
        return _vm.queryBasketballScroll();
      }
    }
  }, [_vm._v("刷新")]), _c("div", {
    class: _vm.basketball.scroll.config.autoRefresh ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.basketball.scroll.config.autoRefresh = !_vm.basketball.scroll.config.autoRefresh;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.scroll.config.autoRefresh
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("自动刷新")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input"
  }, [_c("div", [_vm._v("间隔")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.scroll.config.refreshInterval,
      expression: "basketball.scroll.config.refreshInterval"
    }],
    domProps: {
      value: _vm.basketball.scroll.config.refreshInterval
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.basketball.scroll.config, "refreshInterval", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("秒")])]), _c("div", {
    class: _vm.basketball.scroll.config.floatWarning1 ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.basketball.scroll.config.floatWarning1 = !_vm.basketball.scroll.config.floatWarning1;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.scroll.config.floatWarning1
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("全场报警")])]), _c("div", {
    class: _vm.basketball.scroll.config.floatWarning2 ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.basketball.scroll.config.floatWarning2 = !_vm.basketball.scroll.config.floatWarning2;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.scroll.config.floatWarning2
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("半场报警")])])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-body freq"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-body-middle"
  }, [_vm._m(0), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input-3"
  }, [_c("input", {
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.sizeFreqTC.team === 2
    },
    on: {
      change: function change($event) {
        return _vm.sizeFreqTCCheckChanged($event, 2);
      }
    }
  }), _c("div", [_vm._v("主/客Ai【实时】")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input-3"
  }, [_c("input", {
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.sizeFreq.team === 2
    },
    on: {
      change: function change($event) {
        return _vm.sizeFreqCheckChanged($event, 2);
      }
    }
  }), _c("div", [_vm._v("主/客Ai【数据】")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input-3"
  }, [_c("div", [_vm._v("高/低于")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sizeFreq.rate,
      expression: "sizeFreq.rate"
    }],
    staticClass: "input",
    domProps: {
      value: _vm.sizeFreq.rate
    },
    on: {
      change: _vm.sizeFreqRateChanged,
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.sizeFreq, "rate", $event.target.value);
      }
    }
  }), _c("div", [_vm._v("%")])])])])])] : _vm._e()], 2) : _vm._e(), _vm.current.code === "hedging" ? _c("div", {
    staticClass: "container-left-head-basketball-condition"
  }, [_vm.hedging.mode === 0 ? [_c("div", {
    staticClass: "container-left-head-basketball-condition-title"
  }, [_vm._v("威圖对冲")]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content"
  }, [_c("div", {
    staticClass: "container-left-head-basketball-condition-content-header"
  }, [_c("div", {
    class: _vm.hedging.config.floatWarning ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    on: {
      click: function click($event) {
        _vm.hedging.config.floatWarning = !_vm.hedging.config.floatWarning;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.hedging.config.floatWarning
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("浮动报警")])])])])] : _vm._e()], 2) : _vm._e(), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-head-refresh"
  }, [_c("div", {
    class: _vm.current.refreshTimer === 0 ? "container-left-head-refresh-content" : "container-left-head-refresh-content container-left-head-refresh-content-disable",
    on: {
      click: function click($event) {
        return _vm.query();
      }
    }
  }, [_c("div", {
    staticClass: "container-left-head-refresh-content-body"
  }, [_c("div", {
    staticClass: "container-left-head-refresh-content-body-icon"
  }), _c("div", {
    staticClass: "container-left-head-refresh-content-body-title"
  }, [_vm._v(" " + _vm._s(_vm.current.refreshTimer === 0 ? "刷新" : "".concat(_vm.current.refreshTimer, "\u79D2")))])])])]) : _vm._e()]), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-body"
  }, [_c("div", {
    staticClass: "container-left-body-top",
    on: {
      click: _vm.scrollTop
    }
  }), _c("div", {
    staticClass: "container-left-body-bottom",
    on: {
      click: _vm.scrollBottom
    }
  }), _c("div", {
    staticClass: "container-left-body-title"
  }, [_c("div", {
    staticClass: "container-left-body-title-fav container-left-body-fav"
  }, [_vm._v("收藏")]), _vm.current.code !== "GreatValueData" ? _c("div", {
    staticClass: "container-left-body-title-profit container-left-body-profit"
  }, [_vm._v("利润")]) : _vm._e(), _c("div", {
    staticClass: "container-left-body-title-platform container-left-body-platform"
  }, [_vm._v("参与平台")]), _c("div", {
    staticClass: "container-left-body-title-time container-left-body-time"
  }, [_vm._v("开赛时间")]), _c("div", {
    staticClass: "container-left-body-title-project container-left-body-project"
  }, [_vm._v("项目")]), _c("div", {
    staticClass: "container-left-body-title-position container-left-body-position"
  }, [_vm._v("套利盘口")]), _c("div", {
    staticClass: "container-left-body-title-price container-left-body-price"
  }, [_vm._v("赔率")]), _vm.current.code === "GreatValueData" ? _c("div", {
    staticClass: "container-left-body-title-price container-left-body-overvalueRate"
  }, [_vm._v("超值率")]) : _vm._e()]), _c("div", {
    ref: "content",
    staticClass: "container-left-body-content"
  }, _vm._l(_vm.current.list, function (item, index) {
    return _vm.current.list && _vm.current.list.length > 0 ? _c("div", {
      key: index,
      class: _vm.selectedIndex === index ? "container-left-body-content-item container-left-body-content-item-selected" : "container-left-body-content-item",
      on: {
        click: function click($event) {
          return _vm.selectedItem(index);
        }
      }
    }, [_c("div", {
      staticClass: "container-left-body-fav container-left-body-content-item-fav"
    }, [!item.fav ? _c("span", {
      staticClass: "iconfont icon-shoucang",
      attrs: {
        title: "点击收藏"
      },
      on: {
        click: function click($event) {
          return _vm.favItem(item, true);
        }
      }
    }) : _c("span", {
      staticClass: "iconfont icon-shoucang icon-shoucang-fav",
      attrs: {
        title: "取消收藏"
      },
      on: {
        click: function click($event) {
          return _vm.favItem(item, false);
        }
      }
    })]), _vm.current.code !== "GreatValueData" ? _c("div", {
      staticClass: "container-left-body-content-item-profit container-left-body-profit"
    }, [_vm.current.code !== "RangeArbitrage" ? _c("div", {
      staticClass: "container-left-body-content-item-profit-item"
    }, [_c("div", {
      staticClass: "container-left-body-content-item-profit-item-ratio"
    }, [_c("span", {
      staticClass: "container-left-body-content-item-profit-item-ratio-number"
    }, [_vm._v(_vm._s(item.profit))]), _c("span", {
      staticClass: "container-left-body-content-item-profit-item-ratio-unit"
    })]), _c("div", {
      staticClass: "container-left-body-content-item-profit-item-timer"
    }, [_vm._v("Live" + _vm._s(item.liveTime) + " ")]), _c("div", {
      staticClass: "container-left-body-content-item-profit-item-icon",
      on: {
        click: function click($event) {
          return _vm.queryCalclator(item);
        }
      }
    })]) : _c("div", {
      staticClass: "container-left-body-content-item-profit-item"
    }, [_c("div", {
      staticClass: "container-left-body-content-item-profit-item-ratio"
    }, [_c("span", {
      staticClass: "container-left-body-content-item-profit-item-ratio-number"
    }, [_vm._v(_vm._s(item.rangeTips))]), _c("span", {
      staticClass: "container-left-body-content-item-profit-item-ratio-unit"
    })]), _c("div", {
      staticClass: "container-left-body-content-item-profit-item-timer"
    }, [_vm._v(_vm._s(item.profit) + " ")]), _c("div", {
      staticClass: "container-left-body-content-item-profit-item-icon",
      on: {
        click: function click($event) {
          return _vm.queryCalclator(item);
        }
      }
    })])]) : _vm._e(), _c("div", {
      staticClass: "container-left-body-content-item-platform container-left-body-platform"
    }, _vm._l(item.details, function (child, idx) {
      return _c("div", {
        key: idx,
        staticClass: "container-left-body-content-item-platform-item"
      }, [_c("div", {
        staticClass: "container-left-body-content-item-platform-item-up"
      }, [_vm._v(_vm._s(child.platform) + " ")]), _c("div", {
        staticClass: "container-left-body-content-item-platform-item-down"
      }, [_vm._v(_vm._s(child.sport))])]);
    }), 0), _c("div", {
      staticClass: "container-left-body-content-item-time container-left-body-time"
    }, _vm._l(item.details, function (child, idx) {
      return _c("div", {
        key: idx,
        staticClass: "container-left-body-content-item-time-item"
      }, [_c("div", {
        staticClass: "container-left-body-content-item-time-item-up"
      }, [_vm._v(_vm._s(_vm.timeSpilts(child.time, 0)) + " ")]), _c("div", {
        staticClass: "container-left-body-content-item-time-item-down"
      }, [_vm._v(_vm._s(_vm.timeSpilts(child.time, 1)) + " ")])]);
    }), 0), _c("div", {
      staticClass: "container-left-body-content-item-project container-left-body-project"
    }, _vm._l(item.details, function (child, idx) {
      return _c("div", {
        key: idx,
        staticClass: "container-left-body-content-item-project-item"
      }, [_c("div", {
        staticClass: "container-left-body-content-item-project-item-up",
        on: {
          click: function click($event) {
            return _vm.copyTeam(child);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.lang === "zh" ? child.team : child.teamEn))]), _c("div", {
        staticClass: "container-left-body-content-item-project-item-down",
        on: {
          click: function click($event) {
            return _vm.copySport(child);
          }
        }
      }, [_vm._v(_vm._s(_vm.lang === "zh" ? child.game : child.gameEn))])]);
    }), 0), _c("div", {
      staticClass: "container-left-body-content-item-position container-left-body-position"
    }, _vm._l(item.details, function (child, idx) {
      return _c("div", {
        key: idx,
        staticClass: "container-left-body-content-item-position-item"
      }, [_c("div", {
        staticClass: "container-left-body-content-item-position-item-icon"
      }, [child.handicapTips && child.handicapTips !== "" ? _c("span", {
        staticClass: "title",
        attrs: {
          "data-title": child.handicapTips
        }
      }, [_vm._v("            ")]) : _vm._e()]), _c("div", {
        staticClass: "container-left-body-content-item-position-item-text"
      }, [_vm._v(_vm._s(child.handicap) + " ")])]);
    }), 0), _c("div", {
      staticClass: "container-left-body-content-item-price container-left-body-price"
    }, _vm._l(item.details, function (child, idx) {
      return _c("div", {
        key: idx,
        staticClass: "container-left-body-content-item-price-item"
      }, [_vm._v(_vm._s(child.odd))]);
    }), 0), _vm.current.code === "GreatValueData" ? _c("div", {
      staticClass: "container-left-body-content-item-overvalueRate container-left-body-overvalueRate"
    }, [_c("div", {
      staticClass: "container-left-body-content-item-overvalueRate-item"
    }, [_vm._v(_vm._s(item.profit))])]) : _vm._e()]) : _vm._e();
  }), 0)]) : _vm._e(), _vm.current.code === "Basketball" ? _c("div", {
    staticClass: "container-left-body"
  }, [_c("div", {
    staticClass: "container-left-body-basketball"
  }, [_vm.watchScroll ? _c("div", {
    staticClass: "container-left-body-basketball-title"
  }, [_c("div", {
    staticClass: "container-left-body-basketball-title-left"
  }, [_vm._v(" ")]), _c("div", {
    staticClass: "container-left-body-basketball-title-content"
  }, [_c("div", {
    class: _vm.basketball.mode === 0 ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    attrs: {
      disabled: _vm.basketball.mode !== 0
    },
    on: {
      click: function click($event) {
        _vm.basketball.mode = 0;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.mode === 0
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("赛前盘")])]), _c("div", {
    class: _vm.basketball.mode === 1 ? "container-left-head-basketball-condition-content-item container-left-head-basketball-condition-content-item-checked" : "container-left-head-basketball-condition-content-item",
    attrs: {
      disabled: _vm.basketball.mode !== 1
    },
    on: {
      click: function click($event) {
        _vm.basketball.mode = 1;
      }
    }
  }, [_c("input", {
    staticClass: "container-left-head-basketball-condition-content-checkbox container-checkbox",
    attrs: {
      type: "checkbox"
    },
    domProps: {
      checked: _vm.basketball.mode === 1
    }
  }), _c("label", {
    staticClass: "checkbox"
  }), _c("label", [_vm._v("滚球盘")])]), _c("div", {
    staticClass: "container-left-head-basketball-condition-content-condition"
  }, [_vm.basketball.mode === 1 ? _c("div", {
    staticClass: "container-left-head-basketball-condition-content-condition-item"
  }, [_c("select", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basketball.scroll.config.skipView,
      expression: "basketball.scroll.config.skipView"
    }],
    on: {
      change: function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.basketball.scroll.config, "skipView", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c("option", {
    attrs: {
      value: "1"
    }
  }, [_vm._v("全部比赛")]), _c("option", {
    attrs: {
      value: "2"
    }
  }, [_vm._v("已过滤(" + _vm._s(_vm.basketball.scroll.config.skips ? _vm.basketball.scroll.config.skips.length : 0) + ") ")])])]) : _vm._e()])]), _c("div", {
    staticClass: "container-left-body-basketball-title-right"
  }, [_vm._v(" ")])]) : _vm._e(), _vm.basketball.mode === 0 ? [_c("div", {
    staticClass: "table-item-data table-fixed"
  }, [_c("table", [_vm._m(1), _vm._l(_vm.basketball.today.list, function (item, index) {
    return _c("tbody", {
      key: index,
      class: _vm.selectedIndex === index ? "basketball-item basketball-item-selected" : "basketball-item",
      on: {
        click: function click($event) {
          _vm.selectedIndex = index;
        }
      }
    }, [_c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 0)) + " ")]), _c("td", {
      staticClass: "team-child-1 basketball-name",
      attrs: {
        rowspan: "2"
      }
    }, [_c("span", {
      staticClass: "basketball-game-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketballGame(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name))])]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(_vm._s(item.team1))])]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 0, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 0, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 1, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-2 team-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 1, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4 team-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "b", 1)) + " ")])]), _c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 1)) + " ")]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.team2))])]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 0, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 0, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 1, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-2 team-2"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d0, 1, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4 team-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "b", 2)) + " ")])])]);
  })], 2)]), _c("div", {
    ref: "basketballToDayWarning",
    staticClass: "table-item-warning table-fixed",
    style: _vm.todayWarningHeight
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "table-warning-header"
  }, [_c("th", {
    attrs: {
      colspan: "15"
    }
  }, [_c("div", {
    staticClass: "table-warning-header-content"
  }, [_c("div", {
    staticClass: "table-warning-header-content-title"
  }, [_vm._v("报警数据")]), _c("div", {
    staticClass: "table-warning-header-content-empty"
  }, [_vm._v(" ")]), _vm.change > 0 ? _c("div", {
    staticClass: "table-warning-header-content-refresh-time"
  }, [_vm._v(" 刷新时间:" + _vm._s(_vm.dayjs(_vm.basketball.today.time).format("YYYY-MM-DD HH:mm:ss")) + " ")]) : _vm._e()])])])]), _vm._l(_vm.basketball.today.warnings, function (item, index) {
    return _c("tbody", {
      key: index,
      class: _vm.warningSelectIndex === index ? "basketball-item basketball-item-selected" : "basketball-item",
      on: {
        click: function click($event) {
          _vm.warningSelectIndex = index;
        }
      }
    }, [_c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 0)) + " ")]), _c("td", {
      staticClass: "team-child-1 basketball-name",
      attrs: {
        rowspan: "2"
      }
    }, [_c("span", {
      staticClass: "basketball-game-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketballGame(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name))])]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_vm.watchBasketBallHis ? _c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content"
    }, [_c("div", {
      staticClass: "basketball-operation-content-title"
    }, [_vm._v(_vm._s(item.team1) + " - " + _vm._s(item.team2) + " 滚球高低占比")]), _c("div", {
      staticClass: "basketball-operation-content-buttons"
    }, [_c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item, "全场", "让球");
        }
      }
    }, [_vm._v("全场让球")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item, "全场", "大小");
        }
      }
    }, [_vm._v("全场大小")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item, "半场", "让球");
        }
      }
    }, [_vm._v("半场让球")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item, "半场", "大小");
        }
      }
    }, [_vm._v("半场大小")])])]), _c("span", {
      staticClass: "basketball-team-1",
      attrs: {
        slot: "reference"
      },
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      },
      slot: "reference"
    }, [_vm._v(" " + _vm._s(item.team1))])]) : _c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(_vm._s(item.team1))])], 1), _c("td", {
      staticClass: "team-child-2 team-2 basketball-warning-time-1",
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" 报警时间 ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "b", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "r", 1)) + " ")]), _c("td", {
      staticClass: "team-child-4 team-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "b", 1)) + " ")])]), _c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 1)) + " ")]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(_vm._s(item.team2))])]), _c("td", {
      staticClass: "team-child-2 team-2 basketball-warning-time-2",
      attrs: {
        colspan: "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.dayjs(item.time).format("MM-DD HH:mm:ss")) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 0, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d1, 1, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 0, "b", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "r", 2)) + " ")]), _c("td", {
      staticClass: "team-child-4 team-4"
    }, [_vm._v(" " + _vm._s(_vm.spiltBasketball(item.data.d2, 1, "b", 2)) + " ")])])]);
  })], 2)])] : _vm._e(), _vm.basketball.mode === 1 ? [_c("div", {
    staticClass: "table-item-data table-fixed"
  }, [_c("table", [_vm._m(2), _vm._l(_vm.basketball.scroll.list, function (item, index) {
    return _vm.renderScrollBasketBall(item) ? _c("tbody", {
      key: index,
      class: _vm.selectedIndex === index ? "basketball-item basketball-item-selected" : _vm.isLastScrollWarning(item.pid) === true ? "basketball-item basketball-item-warning" : "basketball-item",
      on: {
        click: function click($event) {
          _vm.selectedIndex = index;
        }
      }
    }, [_c("tr", [_c("td", {
      staticClass: "team-child-1",
      attrs: {
        rowspan: "2"
      }
    }, [_vm.isInSkips(item) === false ? _c("div", {
      staticClass: "team-view-1",
      attrs: {
        title: "隐藏本场比赛"
      },
      on: {
        click: function click($event) {
          return _vm.addSkips(item);
        }
      }
    }, [_c("div", {
      staticClass: "el-icon-close-notification",
      staticStyle: {
        "font-size": "32px"
      }
    }, [_vm._v("  ")])]) : _c("div", {
      staticClass: "team-view-2",
      attrs: {
        title: "查看本场比赛"
      },
      on: {
        click: function click($event) {
          return _vm.delSkips(item);
        }
      }
    }, [_c("div", {
      staticClass: "el-icon-view",
      staticStyle: {
        "font-size": "32px"
      }
    }, [_vm._v(" ")])])]), _c("td", {
      staticClass: "team-child-1 center bold"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.basketball.competingTime, 0)) + " ")]), _c("td", {
      staticClass: "team-child-1 basketball-name",
      attrs: {
        rowspan: "2"
      }
    }, [_c("span", {
      staticClass: "basketball-game-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketballGame(item.basketball);
        }
      }
    }, [_vm._v(" " + _vm._s(item.basketball.name))])]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [false ? _c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content"
    }, [_vm.watchBasketBallHis ? _c("div", {
      staticClass: "basketball-operation-content-title"
    }, [_vm._v(_vm._s(item.basketball.team1) + " - " + _vm._s(item.basketball.team2) + " ")]) : _vm._e(), _vm.watchBasketBallHis ? _c("div", {
      staticClass: "basketball-operation-content-buttons"
    }, [_c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item.basketball, "全场让球");
        }
      }
    }, [_vm._v("初盘全场让球胜率")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item.basketball, "全场大小");
        }
      }
    }, [_vm._v("初盘全场大小胜率")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item.basketball, "半场让球");
        }
      }
    }, [_vm._v("初盘半场让球胜率")]), _c("div", {
      staticClass: "basketball-operation-content-buttons-item",
      on: {
        click: function click($event) {
          return _vm.openHistory(item.basketball, "半场大小");
        }
      }
    }, [_vm._v("初盘半场大小胜率")])]) : _vm._e()]), _c("div", {
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item.basketball);
        }
      }
    }, [_vm._v(" " + _vm._s(item.basketball.team1))])])]) : _c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item.basketball);
        }
      }
    }, [_vm._v(_vm._s(item.basketball.team1))])], 1), _c("td", {
      staticClass: "team-child-2 align-center-1"
    }, [item.rv3 ? _c("span", [_vm._v(_vm._s(-item.rv3 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv3)) + " ")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-2",
      attrs: {
        rowspan: "2"
      }
    }, [item.bv3 ? _c("span", [_vm._v(" " + _vm._s(item.bv3) + " ")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-2 align-center-1"
    }, [item.rv4 ? _c("span", [_vm._v(_vm._s(-item.rv4 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv4)) + " ")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-2 team-2",
      attrs: {
        rowspan: "2"
      }
    }, [item.bv4 ? _c("span", [_vm._v(" " + _vm._s(item.bv4))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3 align-center-1"
    }, [item.rv1 ? _c("span", [_vm._v(_vm._s(-item.rv1 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv1)))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3",
      attrs: {
        rowspan: "2"
      }
    }, [item.bv1 ? _c("span", [_vm._v(" " + _vm._s(item.bv1))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3 align-center-1"
    }, [item.rv2 ? _c("span", [_vm._v(_vm._s(-item.rv2 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv2)))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3 team-3",
      attrs: {
        rowspan: "2"
      }
    }, [item.bv2 ? _c("span", [_vm._v(" " + _vm._s(item.bv2))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-4"
    }, [item.ov1 || item.ov1 === 0 ? _c("span", {
      staticClass: "height-val"
    }, [_vm._v("高位")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-4",
      attrs: {
        rowspan: "2"
      }
    }, [item.ov1 || item.ov1 === 0 ? _c("button", {
      staticClass: "real-time-1",
      on: {
        click: function click($event) {
          return _vm.openSizeRealTime(item, "全场");
        }
      }
    }, [_vm._v("大小走势图")]) : _vm._e(), !(item.ov1 || item.ov1 === 0) && item.rv1 ? _c("button", {
      staticClass: "real-time-2",
      on: {
        click: function click($event) {
          return _vm.openLetRealTime(item, "全场");
        }
      }
    }, [_vm._v("让球走势图")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-4"
    }, [item.un1 || item.un1 === 0 ? _c("span", {
      staticClass: "lower-val"
    }, [_vm._v("低位")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-4 team-4a",
      attrs: {
        rowspan: "2"
      }
    }, [item.un1 || item.un1 === 0 ? _c("button", {
      staticClass: "real-time-1",
      on: {
        click: function click($event) {
          return _vm.openSizeRealTime(item, "全场");
        }
      }
    }, [_vm._v("大小走势图")]) : _vm._e(), !(item.un1 || item.un1 === 0) && item.rv1 ? _c("button", {
      staticClass: "real-time-2",
      on: {
        click: function click($event) {
          return _vm.openLetRealTime(item, "全场");
        }
      }
    }, [_vm._v("让球走势图")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-5"
    }, [item.ov2 || item.ov2 === 0 ? _c("span", {
      staticClass: "height-val"
    }, [_vm._v("高位")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-5",
      attrs: {
        rowspan: "2"
      }
    }, [item.ov2 || item.ov2 === 0 ? _c("button", {
      staticClass: "real-time-1",
      on: {
        click: function click($event) {
          return _vm.openSizeRealTime(item, "半场");
        }
      }
    }, [_vm._v("大小走势图")]) : _vm._e(), !(item.ov2 || item.ov2 === 0) && item.rv2 ? _c("button", {
      staticClass: "real-time-2",
      on: {
        click: function click($event) {
          return _vm.openLetRealTime(item, "半场");
        }
      }
    }, [_vm._v("让球走势图")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-5"
    }, [item.un2 || item.un2 === 0 ? _c("span", {
      staticClass: "lower-val"
    }, [_vm._v("低位")]) : _vm._e()]), _c("td", {
      staticClass: "team-child-5 team-4",
      attrs: {
        rowspan: "2"
      }
    }, [item.un2 || item.un2 === 0 ? _c("button", {
      staticClass: "real-time-1",
      on: {
        click: function click($event) {
          return _vm.openSizeRealTime(item, "半场");
        }
      }
    }, [_vm._v("大小走势图")]) : _vm._e(), !(item.un2 || item.un2 === 0) && item.rv2 ? _c("button", {
      staticClass: "real-time-2",
      on: {
        click: function click($event) {
          return _vm.openLetRealTime(item, "半场");
        }
      }
    }, [_vm._v("让球走势图")]) : _vm._e()])]), _c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.basketball.competingTime, 1)) + " ")]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item.basketball);
        }
      }
    }, [_vm._v(" " + _vm._s(item.basketball.team2))])]), _c("td", {
      staticClass: "team-child-2 align-center-1"
    }, [item.rv3 ? _c("span", [_vm._v(_vm._s(item.rv3 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv3)) + " ")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-2 align-center-1"
    }, [item.rv4 ? _c("span", [_vm._v(_vm._s(item.rv4 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv4)) + " ")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3 align-center-1"
    }, [item.rv1 ? _c("span", [_vm._v(_vm._s(item.rv1 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv1)))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-3 align-center-1"
    }, [item.rv2 ? _c("span", [_vm._v(_vm._s(item.rv2 > 0 ? "+" : "- ") + _vm._s(Math.abs(item.rv2)))]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-4"
    }, [item.ov1 || item.ov1 === 0 ? _c("span", {
      staticClass: "height-val"
    }, [_vm._v(" " + _vm._s(item.ov1) + "%")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-4"
    }, [item.un1 || item.un1 === 0 ? _c("span", {
      staticClass: "lower-val"
    }, [_vm._v(" " + _vm._s(_vm.basketballLowerValue(item.un1)) + "%")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-5"
    }, [item.ov2 || item.ov2 === 0 ? _c("span", {
      staticClass: "height-val"
    }, [_vm._v(" " + _vm._s(item.ov2) + "%")]) : _c("span", [_vm._v("/")])]), _c("td", {
      staticClass: "team-child-5"
    }, [item.un2 || item.un2 === 0 ? _c("span", {
      staticClass: "lower-val"
    }, [_vm._v(" " + _vm._s(_vm.basketballLowerValue(item.un2)) + "%")]) : _c("span", [_vm._v("/")])])])]) : _vm._e();
  })], 2)])] : _vm._e()], 2)]) : _vm._e(), _vm.current.code === "hedging" ? _c("div", {
    staticClass: "container-left-body"
  }, [_c("div", {
    staticClass: "container-left-body-basketball"
  }, [_vm._m(3), _c("div", {
    staticClass: "table-item-data table-fixed"
  }, [_c("table", [_vm._m(4), _vm._l(_vm.hedging.list, function (item, index) {
    return _c("tbody", {
      key: index,
      class: _vm.selectedIndex === index ? "basketball-item basketball-item-selected" : "basketball-item",
      on: {
        click: function click($event) {
          _vm.selectedIndex = index;
        }
      }
    }, [_c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.basketball.competingTime, 0)) + " ")]), _c("td", {
      staticClass: "team-child-1 basketball-name",
      attrs: {
        rowspan: "2"
      }
    }, [_c("span", {
      staticClass: "basketball-game-1",
      on: {
        click: function click($event) {
          return _vm.copyBet188Game(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.basketball.name))])]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBet188(item);
        }
      }
    }, [_vm._v(_vm._s(item.basketball.team1))])]), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 0, "r", 1))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "r", 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "r", 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 0, "b", 1))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "b", 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "b", 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 1, "r", 1))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "r", 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "r", 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 1, "b", 1))
      }
    }), _c("td", {
      staticClass: "team-child-2 team-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "b", 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "b", 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 0, "r", item.teamsMatch ? 1 : 2))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "r", item.teamsMatch ? 1 : 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "r", item.teamsMatch ? 1 : 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 0, "b", item.teamsMatch ? 1 : 2))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "b", item.teamsMatch ? 1 : 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "b", item.teamsMatch ? 1 : 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 1, "r", item.teamsMatch ? 1 : 2))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "r", item.teamsMatch ? 1 : 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "r", item.teamsMatch ? 1 : 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 1, "b", item.teamsMatch ? 1 : 2))
      }
    }), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "b", item.teamsMatch ? 1 : 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "b", item.teamsMatch ? 1 : 2))
      },
      slot: "reference"
    })])], 1)]), _c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.basketball.competingTime, 1)) + " ")]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBet188(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.basketball.team2))])]), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 0, "r", 2))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "r", 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "r", 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 0, "b", 2))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "b", 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 0, "b", 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 1, "r", 2))
      }
    }), _c("td", {
      staticClass: "team-child-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "r", 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "r", 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-2",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, true, 1, "b", 2))
      }
    }), _c("td", {
      staticClass: "team-child-2 team-2"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "b", 2, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, true, 1, "b", 2))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 0, "r", item.teamsMatch ? 2 : 1))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "r", item.teamsMatch ? 2 : 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "r", item.teamsMatch ? 2 : 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 0, "b", item.teamsMatch ? 2 : 1))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "b", item.teamsMatch ? 2 : 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 0, "b", item.teamsMatch ? 2 : 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 1, "r", item.teamsMatch ? 2 : 1))
      }
    }), _c("td", {
      staticClass: "team-child-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "r", item.teamsMatch ? 2 : 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "r", item.teamsMatch ? 2 : 1))
      },
      slot: "reference"
    })])], 1), _c("td", {
      staticClass: "team-child-3",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188(item, false, 1, "b", item.teamsMatch ? 2 : 1))
      }
    }), _c("td", {
      staticClass: "team-child-3 team-3"
    }, [_c("el-popover", {
      attrs: {
        placement: "right",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "basketball-operation-content",
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "b", item.teamsMatch ? 2 : 1, true))
      }
    }), _c("span", {
      attrs: {
        slot: "reference"
      },
      domProps: {
        innerHTML: _vm._s(_vm.spiltBet188Measure(item, false, 1, "b", item.teamsMatch ? 2 : 1))
      },
      slot: "reference"
    })])], 1)])]);
  })], 2)]), _c("div", {
    ref: "hedgingWarning",
    staticClass: "table-item-warning table-fixed",
    style: _vm.hedgingWarningHeight
  }, [_c("table", [_c("tbody", [_c("tr", {
    staticClass: "table-warning-header"
  }, [_c("th", {
    attrs: {
      colspan: "19"
    }
  }, [_c("div", {
    staticClass: "table-warning-header-content"
  }, [_c("div", {
    staticClass: "table-warning-header-content-title"
  }, [_vm._v("报警数据")]), _c("div", {
    staticClass: "table-warning-header-content-empty"
  }, [_vm._v(" ")]), _vm.change > 0 ? _c("div", {
    staticClass: "table-warning-header-content-refresh-time"
  }, [_vm._v(" 刷新时间:" + _vm._s(_vm.dayjs(_vm.hedging.time).format("YYYY-MM-DD HH:mm:ss")) + " ")]) : _vm._e()])])])]), _vm._l(_vm.hedging.warnings, function (item, index) {
    return _c("tbody", {
      key: index,
      class: _vm.warningSelectIndex === index ? "basketball-item basketball-item-selected" : "basketball-item",
      on: {
        click: function click($event) {
          _vm.warningSelectIndex = index;
        }
      }
    }, [_c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 0)) + " ")]), _c("td", {
      staticClass: "team-child-1 basketball-name",
      attrs: {
        rowspan: "2"
      }
    }, [_c("span", {
      staticClass: "basketball-game-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketballGame(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name))])]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(_vm._s(item.team1))])]), _c("td", {
      staticClass: "team-child-2 basketball-warning-time-1",
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" 报警时间 ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(item.mode === 0 ? "全场" : "半场") + " - "), _c("span", {
      domProps: {
        innerHTML: _vm._s(_vm.buildBet188LetSize(item, true))
      }
    })]), _c("td", {
      staticClass: "team-child-2",
      attrs: {
        colspan: "2"
      }
    }, [_c("span", {
      staticStyle: {
        color: "red"
      }
    }, [_vm._v("皇冠")]), _vm._v(" : "), _c("span", {
      staticClass: "bet188-team-1",
      staticStyle: {
        color: "red"
      },
      on: {
        click: function click($event) {
          return _vm.copyBetWarningTeam(item.betTeam);
        }
      }
    }, [_vm._v(_vm._s(item.betTeam))])]), _c("td", {
      staticClass: "team-child-2 team-2",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.mode === 0 ? "全场" : "半场") + " ｜ " + _vm._s(item.betOdds.toFixed(3)) + " ｜ " + _vm._s(item.letSize === 0 && item.betValue > 0 ? "+" + item.betValue.toFixed(1) : item.betValue.toFixed(1).toString().replace("-", "- ")) + " ")]), _c("td", {
      staticClass: "team-child-3",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" 对冲公式 ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" 返还率 ")]), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3 team-3",
      attrs: {
        rowspan: "2"
      }
    }, [_c("button", {
      staticClass: "cal-button",
      on: {
        click: function click($event) {
          return _vm.openCalculatorRule(item.betOdds + 1, item.startRealOdds);
        }
      }
    }, [_vm._v("计算器")])])]), _c("tr", [_c("td", {
      staticClass: "team-child-1"
    }, [_vm._v(" " + _vm._s(_vm.timeSpilts(item.competingTime, 1)) + " ")]), _c("td", {
      staticClass: "team-child-1 team-1 basketball-team"
    }, [_c("span", {
      staticClass: "basketball-team-1",
      on: {
        click: function click($event) {
          return _vm.copyBasketball(item);
        }
      }
    }, [_vm._v(_vm._s(item.team2))])]), _c("td", {
      staticClass: "team-child-2 basketball-warning-time-2",
      attrs: {
        colspan: "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.dayjs(item.now).format("MM-DD HH:mm:ss")) + " ")]), _c("td", {
      staticClass: "team-child-2"
    }, [_vm._v(" " + _vm._s(item.letSize === 0 ? "让球" : "大小") + " - "), _c("span", {
      domProps: {
        innerHTML: _vm._s(_vm.buildBet188LetSize(item, false))
      }
    })]), _c("td", {
      staticClass: "team-child-2",
      attrs: {
        colspan: "2"
      }
    }, [_c("span", {
      staticStyle: {
        color: "green"
      }
    }, [_vm._v("平博")]), _vm._v(" : "), _c("span", {
      staticClass: "bet188-team-1",
      staticStyle: {
        color: "green"
      },
      on: {
        click: function click($event) {
          return _vm.copyBetWarningTeam(item.startTeam);
        }
      }
    }, [_vm._v(_vm._s(item.startTeam))])]), _c("td", {
      staticClass: "team-child-2 team-2",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.mode === 0 ? "全场" : "半场") + " ｜ " + _vm._s(item.startOdds.toFixed(3)) + " ｜ " + _vm._s(item.letSize === 0 && item.startValue > 0 ? "+" + item.startValue.toFixed(1) : item.startValue.toFixed(1)) + " ")]), _c("td", {
      staticClass: "team-child-3",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" " + _vm._s(item.compute) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }, [_vm._v(" " + _vm._s(item.computeValue.toFixed(3)) + " ")]), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3"
    }), _c("td", {
      staticClass: "team-child-3",
      staticStyle: {
        "border-right": "0px !important"
      }
    })])]);
  })], 2)])])]) : _vm._e(), _c("div", {
    staticClass: "container-left-footer"
  }, [_c("div", {
    ref: "notice",
    class: _vm.current.code === "Basketball" || _vm.current.code === "hedging" ? "container-left-footer-remark container-left-footer-remark-basketball" : "container-left-footer-remark"
  }, [_vm._v(_vm._s(_vm.notices[_vm.notice]))]), _c("div", {
    staticClass: "container-left-footer-time"
  }, [_vm._v("有效期至:" + _vm._s(_vm.expriesTime))]), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-left-footer-page"
  }, [_c("div", {
    class: _vm.previewCursorEnable ? "container-left-footer-page-previous" : "container-left-footer-page-previous-disabled",
    attrs: {
      disabled: !_vm.previewCursorEnable
    },
    on: {
      click: function click($event) {
        return _vm.goPage("preview");
      }
    }
  }, [_vm._v(" 上一页")]), _c("div", {
    class: _vm.nextCursorEnable ? "container-left-footer-page-next" : "container-left-footer-page-next-disabled",
    attrs: {
      disabled: !_vm.nextCursorEnable
    },
    on: {
      click: function click($event) {
        return _vm.goPage("next");
      }
    }
  }, [_vm._v("下一页 ")])]) : _vm._e()])]), _vm.current.code !== "Basketball" && _vm.current.code !== "hedging" ? _c("div", {
    staticClass: "container-right"
  }, [_c("div", {
    staticClass: "container-right-calc"
  }, [_c("div", {
    staticClass: "container-right-calc-title"
  }, [_vm._v("便捷计算器")]), _c("div", {
    staticClass: "container-right-calc-content"
  }, _vm._l(_vm.calculationData, function (item, index) {
    return _c("div", {
      key: "cl_" + index,
      staticClass: "container-right-calc-content-item",
      on: {
        click: function click($event) {
          return _vm.openCalculator(index + 1);
        }
      }
    }, [_c("div", {
      staticClass: "container-right-calc-content-item-left"
    }, [_c("div", {
      staticClass: "container-right-calc-content-item-left-up"
    }), _c("div", {
      staticClass: "container-right-calc-content-item-left-number"
    }, [_vm._v(_vm._s("".concat(item.left)))]), _c("div", {
      staticClass: "container-right-calc-content-item-left-up"
    })]), _c("div", {
      staticClass: "container-right-calc-content-item-center"
    }, [_vm._v("---")]), _c("div", {
      staticClass: "container-right-calc-content-item-right"
    }, [_c("div", {
      staticClass: "container-right-calc-content-item-right-up"
    }), _c("div", {
      staticClass: "container-right-calc-content-item-right-number"
    }, [_vm._v(_vm._s("".concat(item.right)))]), _c("div", {
      staticClass: "container-right-calc-content-item-right-up"
    })])]);
  }), 0)]), _c("div", {
    staticClass: "container-right-category"
  }, [_c("div", {
    staticClass: "container-right-category-item"
  }, [_c("div", {
    staticClass: "container-right-category-item-title"
  }, [_vm._v("体育类")]), _c("div", {
    staticClass: "container-right-category-item-content"
  }, [_c("div", {
    staticClass: "container-right-category-item-content-all",
    on: {
      click: _vm.allTysSportChecked
    }
  }, [_c("input", {
    attrs: {
      type: "checkbox",
      disabled: _vm.current.allTysSportChecked
    },
    domProps: {
      checked: _vm.current.allTysSportChecked
    }
  }), _c("span", [_vm._v("全选")])]), _c("div", {
    staticClass: "container-right-category-item-content-body"
  }, _vm._l(_vm.current.tysSports, function (item, index) {
    return _c("div", {
      key: index,
      class: item.checked ? "container-right-category-item-content-body-item container-right-category-item-content-body-item-checked" : "container-right-category-item-content-body-item",
      attrs: {
        category: "sport"
      },
      on: {
        click: function click($event) {
          item.checked = !item.checked;
        }
      }
    }, [_c("input", {
      staticClass: "container-right-category-item-content-body-item-checkbox container-checkbox",
      attrs: {
        type: "checkbox"
      },
      domProps: {
        checked: item.checked
      }
    }), _c("label", {
      staticClass: "checkbox"
    }), _c("label", [_vm._v(_vm._s(item.nameCn))])]);
  }), 0)]), _c("div", {
    staticClass: "container-right-category-item-title"
  }, [_vm._v("竞技类")]), _c("div", {
    staticClass: "container-right-category-item-content"
  }, [_c("div", {
    staticClass: "container-right-category-item-content-all",
    on: {
      click: _vm.allGmeSportChecked
    }
  }, [_c("input", {
    attrs: {
      type: "checkbox",
      disabled: _vm.current.allGmeSportChecked
    },
    domProps: {
      checked: _vm.current.allGmeSportChecked
    }
  }), _c("span", [_vm._v("全选")])]), _c("div", {
    staticClass: "container-right-category-item-content-body"
  }, _vm._l(_vm.current.gmeSports, function (item, index) {
    return _c("div", {
      key: index,
      class: item.checked ? "container-right-category-item-content-body-item container-right-category-item-content-body-item-checked" : "container-right-category-item-content-body-item",
      attrs: {
        category: "sport"
      },
      on: {
        click: function click($event) {
          item.checked = !item.checked;
        }
      }
    }, [_c("input", {
      staticClass: "container-right-category-item-content-body-item-checkbox container-checkbox",
      attrs: {
        type: "checkbox"
      },
      domProps: {
        checked: item.checked
      }
    }), _c("label", {
      staticClass: "checkbox"
    }), _c("label", [_vm._v(_vm._s(item.nameCn))])]);
  }), 0)])])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-left-head-basketball-condition-content-input-3"
  }, [_c("div", [_vm._v("大小盘口：")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tbody", {
    staticClass: "header"
  }, [_c("tr", {
    staticClass: "header-title-1"
  }, [_c("th", {
    staticClass: "team-1",
    attrs: {
      colspan: "3"
    }
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-2",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("初盘")]), _c("th", {
    staticClass: "team-3",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("最近")]), _c("th", {
    staticClass: "team-4",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("本次")])]), _c("tr", {
    attrs: {
      header: "header-title-2"
    }
  }, [_c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("时间")]), _c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-child-1 team-1"
  }, [_vm._v("球队")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-2 team-2"
  }, [_vm._v("半场大小")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-3 team-3"
  }, [_vm._v("半场大小")]), _c("th", {
    staticClass: "team-child-4"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-4"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-4"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-4 team-4"
  }, [_vm._v("半场大小")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tbody", {
    staticClass: "header"
  }, [_c("tr", {
    staticClass: "header-title-1"
  }, [_c("th", {
    staticClass: "team-1",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-2",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("初盘")]), _c("th", {
    staticClass: "team-3",
    attrs: {
      colspan: "4"
    }
  }, [_vm._v("实时")]), _c("th", {
    staticClass: "team-4a",
    attrs: {
      colspan: "4",
      rowspan: "2"
    }
  }, [_vm._v("全场【大小/让球】")]), _c("th", {
    staticClass: "team-5",
    attrs: {
      colspan: "4",
      rowspan: "2"
    }
  }, [_vm._v("半场【大小/让球】")])]), _c("tr", {
    attrs: {
      header: "header-title-2"
    }
  }, [_c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("查看/隐藏")]), _c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("时间")]), _c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-child-1 team-1"
  }, [_vm._v("球队")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-2 team-2"
  }, [_vm._v("半场大小")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-3 team-3"
  }, [_vm._v("半场大小")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container-left-body-basketball-title"
  }, [_c("div", {
    staticClass: "container-left-body-basketball-title-left"
  }, [_vm._v(" ")]), _c("div", {
    staticClass: "container-left-body-basketball-title-content"
  }), _c("div", {
    staticClass: "container-left-body-basketball-title-right"
  }, [_vm._v(" ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tbody", {
    staticClass: "header"
  }, [_c("tr", {
    staticClass: "header-title-1"
  }, [_c("th", {
    staticClass: "team-1",
    attrs: {
      colspan: "3"
    }
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-2",
    attrs: {
      colspan: "8"
    }
  }, [_vm._v("皇冠")]), _c("th", {
    staticClass: "team-3",
    attrs: {
      colspan: "8"
    }
  }, [_vm._v("平博")])]), _c("tr", {
    attrs: {
      header: "header-title-2"
    }
  }, [_c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("时间")]), _c("th", {
    staticClass: "team-child-1"
  }, [_vm._v("赛事")]), _c("th", {
    staticClass: "team-child-1 team-1"
  }, [_vm._v("球队")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-2"
  }, [_vm._v("半场大小")]), _c("th", {
    staticClass: "team-child-2 team-2"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场让球")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("全场大小")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("半场让球")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("赔率涨跌")]), _c("th", {
    staticClass: "team-child-3"
  }, [_vm._v("半场大小")]), _c("th", {
    staticClass: "team-child-3 team-3"
  }, [_vm._v("赔率涨跌")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };