export default (function (_ref) {
  var service = _ref.service,
    request = _ref.request,
    serviceForMock = _ref.serviceForMock,
    requestForMock = _ref.requestForMock,
    mock = _ref.mock,
    faker = _ref.faker,
    tools = _ref.tools;
  return {
    /**
     * 我的收藏
     */
    BssSurebetFavMy: function BssSurebetFavMy() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/BssSurebetFav/My",
        method: "POST",
        data: data
      });
    },
    /**
     * 增加收藏
     */
    BssSurebetFavAddOneEx: function BssSurebetFavAddOneEx() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/BssSurebetFav/AddOneEx",
        method: "POST",
        data: data
      });
    },
    /**
     * 取消收藏
     */
    BssSurebetFavCacnel: function BssSurebetFavCacnel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/BssSurebetFav/Cancel",
        method: "POST",
        data: data
      });
    }
  };
});