import "core-js/modules/es.object.keys.js";
/**
 * @description 安全地解析 json 字符串
 * @param {String} jsonString 需要解析的 json 字符串
 * @param {String} defaultValue 默认值
 */
export function parse() {
  var jsonString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '{}';
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = defaultValue;
  try {
    result = JSON.parse(jsonString);
  } catch (error) {
    console.log(error);
  }
  return result;
}

/**
 * @description 接口请求返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */
export function response() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return [200, {
    code: code,
    msg: msg,
    data: data
  }];
}

/**
 * @description 接口请求返回 正确返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 */
export function responseSuccess() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '成功';
  return response(data, msg);
}

/**
 * @description 接口请求返回 错误返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */
export function responseError() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '请求失败';
  var code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 500;
  return response(data, msg, code);
}