export default (function (_ref) {
  var service = _ref.service,
    request = _ref.request,
    serviceForMock = _ref.serviceForMock,
    requestForMock = _ref.requestForMock,
    mock = _ref.mock,
    faker = _ref.faker,
    tools = _ref.tools;
  return {
    /**
     * 登录
     */
    Login: function Login() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: "/SysUser/Login",
        method: "POST",
        data: data
      });
    }
  };
});